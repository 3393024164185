/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import dayjs from '@/lib/dayjs'

new Component('ride-bid-countdown', [], ((c) => {
  const $this = $(c.this);
  const $biddingHelper = new BiddingHelper();
  const $display = $this.find('.status-message');
  const $loader = $this.find('.loader-spin');
  const $statusData = $this.find('#status-data');
  const $tooltip_time_display = $this.find('.tooltip_time_display');
  const tooltipTimer = $this.data('tooltipInfo');
  const $preferredProviderTooltip = $this.find('.preferred-provider-tooltip');
  const isInPpCommunity = $this.data('isInPpCommunity');
  const $ppTimer = $this.find('.countdown');
  const $ppTimerText = $this.find('.countdown-text');
  const timerInterval = $this.data('interval') ? $this.data('interval') : 1000;
  const $displayIcon = $this.find('.status-icon');
  const DISPATCHER_STATUS = {
    assigned: 'Assigned',
    available: 'Available',
    declined: 'Declined',
    notStarted: 'Not Started',
    preferred: 'Preferred',
    processing: 'Processing',
    responded: 'Responded',
    submitEta: 'Submit ETA',
  };

  // Sets the status message and do some UI changes
  // in `Status` column on the dispatcher dashboard page
  // Also, display message for tooltip timer
  //
  // @param {String} str
  // @return {void}
  const setDisplay = function (str) {
    if (tooltipTimer) {
      if ((str === DISPATCHER_STATUS.processing) || (str === DISPATCHER_STATUS.available)) { str = ''; }
      return $tooltip_time_display.html(str);
    }
    if (str === DISPATCHER_STATUS.responded) {
      if ($displayIcon.is(':hidden')) { $displayIcon.show(); }
      $this.attr('class', 'status -grey');
    } else if ($displayIcon.is(':visible')) { $displayIcon.hide(); }

    if (str === DISPATCHER_STATUS.processing) {
      $this.addClass('-processing');
      if ($loader.is(':hidden')) { $loader.show(); }
    } else {
      $this.removeClass('-processing');
      if ($loader.is(':visible')) { $loader.hide(); }
    }

    if (str === DISPATCHER_STATUS.preferred) {
      $this.addClass('-aqua tooltip');
      if ($preferredProviderTooltip.is(':hidden')) { $preferredProviderTooltip.show(); }
      if ($ppTimer.is(':hidden')) { $ppTimer.show(); }
    } else {
      $this.removeClass('-aqua tooltip');
      if ($preferredProviderTooltip.is(':visible')) { $preferredProviderTooltip.hide(); }
      if ($ppTimer.is(':visible')) { $ppTimer.hide(); }
    }

    return $display.html(str);
  };

  // Sets the status message after cutoff time
  //
  // @param {Time} cutoffTime
  // @param {String} str
  // @return {void}
  const setDisplayAfterCutoff = function (cutoffTime, status) {
    const interval = cutoffTime.diff(dayjs(), 'second') * timerInterval;
    return setTimeout(setDisplay, interval, status);
  };

  // Formats the message to be displayed on status badge
  //
  // @param {Integer} seconds
  // @return {String}
  const formatDisplay = function (seconds) {
    if (tooltipTimer) {
      const minutes = Math.max(1, Math.round(seconds / 60));
      if (isInPpCommunity) {
        return `${minutes} mins left for preferred`;
      }
      return `${minutes} mins left`;
    }
    return `${Math.floor(seconds / 60)} MIN ${seconds % 60} S`;
  };

  // Display timer on the status badge as well as on the
  // tooltip timer on cc ride detail page
  //
  // @param {Time} cutoffTime
  // @return {void}
  var run = function (cutoffTime) {
    if (dayjs().add(1, 'second').isAfter(cutoffTime)) {
      return setDisplay(DISPATCHER_STATUS.processing);
    }
    const timeToCutoff = cutoffTime.diff(dayjs(), 'second');
    const display = formatDisplay(timeToCutoff);
    setDisplay(display);
    const intervalId = setTimeout(run, timerInterval, cutoffTime);
    if ($statusData) { return $statusData.data('interval_id', intervalId); }
  };

  // TODO: Rename to generic name when general community timer is also moved
  // under status badge
  //
  // Display timer under the status badge on the dispatcher community page
  //
  // @param {Time} cutoffTime
  // @return {Void}
  const setPpTimer = function (cutoffTime) {
    let timeDisplayInterval = null;

    run = function () {
      const timeLeft = $biddingHelper.remainingTimeCalculator(cutoffTime);

      if (timeLeft) {
        $ppTimerText.html(timeLeft);
        return $statusData?.data('interval_id', timeDisplayInterval);
      }
      setDisplay(DISPATCHER_STATUS.processing);
      return clearInterval(timeDisplayInterval);
    };

    run();
    return timeDisplayInterval = setInterval(run, timerInterval);
  };

  return $(document).ready(() => {
    const $data = $this.data();
    const countdownLength = $data.biddingWindow;
    const cutoffTime = $data.start && dayjs.utc($data.start).add(countdownLength, 'second');
    if ($data.autoassigned) {
      return setDisplay(DISPATCHER_STATUS.submitEta);
    } if ($data.declined) {
      return setDisplay(DISPATCHER_STATUS.declined);
    } if ($data.willCall) {
      return setDisplay(DISPATCHER_STATUS.notStarted);
    } if ($data.claimed) {
      return setDisplay(DISPATCHER_STATUS.assigned);
    } if ($data.processingRide) {
      return setDisplay(DISPATCHER_STATUS.processing);
    } if ($data.hasBid && cutoffTime) {
      setDisplay(DISPATCHER_STATUS.responded);
      return setDisplayAfterCutoff(cutoffTime, DISPATCHER_STATUS.processing);
    } if ($data.outsideBidWindow) {
      return setDisplay(DISPATCHER_STATUS.available);
    } if ($data.ppCommunity) {
      setDisplay(DISPATCHER_STATUS.preferred);
      return setPpTimer(cutoffTime);
    } if (cutoffTime) {
      return run(cutoffTime);
    }
  });
}));
