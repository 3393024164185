export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type RoundtripApiStatusCode = 'invalid_role';

export interface RoundtripApiMessage {
  description: string;
  /**
   * A custom status code defined and returned by the API. These codes rarely change at the API level
   * and can be used to decide on error handling action.
   */
  statusCode: RoundtripApiStatusCode;
}

export const TRIP_TYPES = {
  one_way: 'one_way',
  round_trip: 'roundtrip',
};

export const US_STATES = {
  ALABAMA: 'AL',
  ALASKA: 'AK',
  'AMERICAN SAMOA': 'AS',
  ARIZONA: 'AZ',
  ARKANSAS: 'AR',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  DELAWARE: 'DE',
  'DISTRICT OF COLUMBIA': 'DC',
  'FEDERATED STATES OF MICRONESIA': 'FM',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  GUAM: 'GU',
  HAWAII: 'HI',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  IOWA: 'IA',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MAINE: 'ME',
  'MARSHALL ISLANDS': 'MH',
  MARYLAND: 'MD',
  MASSACHUSETTS: 'MA',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSISSIPPI: 'MS',
  MISSOURI: 'MO',
  MONTANA: 'MT',
  NEBRASKA: 'NE',
  NEVADA: 'NV',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  'NEW YORK': 'NY',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  'NORTHERN MARIANA ISLANDS': 'MP',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PALAU: 'PW',
  PENNSYLVANIA: 'PA',
  'PUERTO RICO': 'PR',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VERMONT: 'VT',
  'VIRGIN ISLANDS': 'VI',
  VIRGINIA: 'VA',
  WASHINGTON: 'WA',
  'WEST VIRGINIA': 'WV',
  WISCONSIN: 'WI',
  WYOMING: 'WY',
};

export interface Location {
  city: string;
  id: number;
  name: string;
  state: keyof typeof US_STATES;
  stateCode?: string;
  streetAddress: string;
  zipcode: string;
}

/**
 * Key of column that can be excluded from the rides table.
 */
export type ExcludibleColumn =
  | 'vehicle'
  | 'book_time'
  | 'proximity'
  | 'trip_distance'
  | 'weight'
  | 'booker_name'
  | 'pickup_location'
  | 'dropoff_location';

export const VEHICLE_TYPES = {
  als: 'als',
  bls: 'bls',
  cct: 'cct',
  medicalSedan: 'medical_sedan',
  psychTransport: 'psych_transport',
  rideshare: 'rideshare',
  stretcherVan: 'stretcher_van',
  taxi: 'taxi',
  wheelchair: 'wheelchair',
} as const;

export type VehicleType = (typeof VEHICLE_TYPES)[keyof typeof VEHICLE_TYPES];

export const {
  rideshare: _rs, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...DISPATCHER_VEHICLE_TYPES
} = VEHICLE_TYPES;

export const CC_VEHICLE_TYPES = VEHICLE_TYPES;

export const CANCEL_RIDE_TEXT = {
  cancelRide: 'Cancel ride',
  cancelTrip: 'Cancel trip',
  cancelSeries: 'Cancel series',
};

export const CANCEL_RIDE_OPTIONS = {
  singleRide: 'single_ride',
  entireTrip: 'entire_trip',
  entireSeries: 'entire_series',
};

export const AVAILABLE_STATUSES = {
  available: 'available',
  responded: 'responded',
  willCall: 'will_call_available',
  preferred: 'preferred',
  declined: 'declined',
  processing: 'processing',
} as const;

export type AvailableStatus =
  (typeof AVAILABLE_STATUSES)[keyof typeof AVAILABLE_STATUSES];

export const DEFAULT_COMMUNITY_STATUSES = [
  AVAILABLE_STATUSES.available,
  AVAILABLE_STATUSES.preferred,
  AVAILABLE_STATUSES.responded,
  AVAILABLE_STATUSES.willCall,
  AVAILABLE_STATUSES.processing,
];

export const ASSIGNED_STATUSES = {
  assigned: 'assigned',
  inProgress: 'in_progress',
  willCall: 'will_call_assigned',
  submitEta: 'submit_eta',
  completed: 'completed',
  canceled: 'canceled',
} as const;

export type AssignedStatus =
  (typeof ASSIGNED_STATUSES)[keyof typeof ASSIGNED_STATUSES];

export const DEFAULT_SCHEDULED_STATUSES = [
  ASSIGNED_STATUSES.assigned,
  ASSIGNED_STATUSES.inProgress,
  ASSIGNED_STATUSES.submitEta,
  ASSIGNED_STATUSES.willCall,
];

export const ROLES = {
  admin: 'admin',
  careCoordinator: 'care_coordinator',
  dispatcher: 'dispatcher',
  superUser: 'super_user',
} as const;

export type Role = (typeof ROLES)[keyof typeof ROLES];

export type User = {
  email: string;
  hospitalId: number;
  id: number;
  role: Role;
  transportationCompanyId: number;
};

export enum TIMELINESS {
  on_time = 0,
  late_15_min,
  late_30_min,
  late_45_min,
  late_60_min,
  late_over_60_min,
}

/**
 * Roundtrip API Server ride.current_status enum values.
 */
export enum RideCurrentStatus {
  AT_DROPOFF = 'at_dropoff',
  AT_PICKUP = 'at_pickup',
  CANCELED = 'canceled',
  CLAIMED = 'claimed',
  COMPLETED = 'completed',
  INCOMPLETE = 'incompleted',
  QUEUED = 'queued',
  TO_DROPOFF = 'to_dropoff',
  TO_PICKUP = 'to_pickup',
}

export const RIDE_AUTH_STATUS = {
  authorized: 'authorized',
  authorizing: 'authorizing',
  denied: 'denied',
  moreInfoNeeded: 'more_info_needed',
  noAuthorizationRequired: 'no_authorization_required',
} as const;

export type AuthStatus =
  (typeof RIDE_AUTH_STATUS)[keyof typeof RIDE_AUTH_STATUS];

/* eslint-disable camelcase */
export const AUTH_DISPLAY_NAMES = {
  authorized: 'Authorized',
  authorizing: 'Authorizing',
  denied: 'Auth. denied',
  more_info_needed: 'More info needed',
  no_authorization_required: 'No auth. required',
} as const;
/* eslint-enable camelcase */

export const DECLINE_REASONS_TEXT = {
  availability: 'Availability',
  distance: 'Distance',
  payer: 'Payer',
  facility: 'Facility',
  waitingForAuthorization: 'Waiting for authorization',
  other: 'Other',
} as const;

export type DeclineReasons =
  (typeof DECLINE_REASONS_TEXT)[keyof typeof DECLINE_REASONS_TEXT];

export const CANCELLATION_REASONS_TEXT = {
  accidentalBooking: 'Accidental booking',
  appointmentChangedOrCanceled: 'Appointment changed or canceled',
  duplicateBooking: 'Duplicate booking',
  riderCanceledTransportation: 'Rider canceled transportation',
  riderFoundAlternateTransportation: 'Rider found alternate transportation',
  roundtripWasUnableToSecureTransportation:
    'Roundtrip was unable to secure transportation',
  transporationCompanyWasLate: 'Transportation company was late',
  other: 'Other',
} as const;

export type CancellationReasons =
  (typeof CANCELLATION_REASONS_TEXT)[keyof typeof CANCELLATION_REASONS_TEXT];

export const CC_RIDE_STATUS_BADGES = {
  canceled: 'canceled',
  completed: 'completed',
  brokered: 'brokered',
  onHold: 'on_hold',
  willCall: 'will_call',
  incomplete: 'incomplete',
  requested: 'requested',
  findingDriver: 'finding_driver',
  claimed: 'claimed',
  findingProviders: 'finding_providers',
  checkingAvailability: 'checking_availability',
  atPickup: 'at_pickup',
  toPickup: 'to_pickup',
  atDestination: 'at_destination',
  toDestination: 'to_destination',
} as const;

export type CcRideStatusBadges =
  (typeof CC_RIDE_STATUS_BADGES)[keyof typeof CC_RIDE_STATUS_BADGES];

export const DISPATCHER_RIDE_STATUS_BADGE = {
  assigned: 'assigned',
  available: 'available',
  canceled: 'canceled',
  completed: 'completed',
  declined: 'declined',
  inProgress: 'in_progress',
  newRide: 'new_ride',
  notStarted: 'not_started',
  preferred: 'preferred',
  responded: 'responded',
  submitEta: 'submit_eta',
  processing: 'processing',
  willCallAssigned: 'will_call_assigned',
  willCallAvailable: 'will_call_available',
} as const;

export type DispatcherRideStatusBadge =
  (typeof DISPATCHER_RIDE_STATUS_BADGE)[keyof typeof DISPATCHER_RIDE_STATUS_BADGE];

export const DEFAULT_CC_FILTER_STATUSES = Object.values(
  CC_RIDE_STATUS_BADGES,
).filter(
  (status) =>
    status !== CC_RIDE_STATUS_BADGES.canceled &&
    status !== CC_RIDE_STATUS_BADGES.completed,
) as CcRideStatusBadges[];

export type RideStatusBadge = CcRideStatusBadges | DispatcherRideStatusBadge;

export const RIDE_TYPES = {
  oneWay: 'one_way',
  roundTrip: 'round_trip',
} as const;

export type RideType = (typeof RIDE_TYPES)[keyof typeof RIDE_TYPES];

export interface Ride {
  authStatus: AuthStatus | null;
  authorizable: boolean;
  autoAssigned: boolean;
  baseVehicleType: string;
  biddingWindow: number;
  cancelationReason: string | null;
  canceledAt: string | null;
  claimed: boolean;
  communityArrival: string | null;
  completedAt: string | null;
  createdAt: string;
  currentStatus: RideCurrentStatus;
  direction: string;
  discountedFare: number;
  distance: number;
  dropoffLocation: Location;
  dropoffLocationId: number;
  dropoffTime: string | null;
  droppedOffAt: string | null;
  endTime: string;
  hasBid: boolean;
  hospitalName: string;
  id: number;
  isDeclined?: boolean;
  noAuthorizationRequired: boolean;
  otherRideId?: number;
  outsideBidWindow: boolean;
  payerTypeHumanized: string;
  pickedUpAt: string | null;
  pickupLocation: Location;
  pickupLocationId: number;
  ppBiddingWindow: number;
  preferredProviderArrival: string | null;
  processingRide: boolean;
  rideBookerName: string;
  rideBookerPhone?: string;
  rideShareType?: null;
  rideType: RideType;
  rideVehicleDescription: string;
  riderFullName: string;
  rideshare: boolean;
  rideshareVehicleType: false;
  rideshareVendor?: string;
  showVehicleDetails: boolean;
  startTime: string;
  startTimeAwardedBid: string | null;
  statusBadge: RideStatusBadge;
  timeliness: TIMELINESS | null;
  timerStartTime: string;
  timezone: string;
  transportationCompanyId: number;
  transportationCompanyName?: string;
  transportationCompanyPhone?: string;
  tripId: number;
  usesCustomizeDispatchSoftware?: null;
  vehicleDescription?: null;
  vehicleLicensePlate: null;
  vehicleNeedType: string;
  vehicleType: string;
  willCall: boolean;
}

export type StreamableRide = Ride & { streamed?: boolean; updated?: boolean };

export interface Facet {
  count: number;
  id: number;
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface HospitalsFacet extends Facet {}

export interface TransporationCompaniesFacet extends Facet {}

export interface RideBookerFacet extends Facet {
  firstName: string;
  lastName: string;
}

export interface RideListFacets {
  hospitals: HospitalsFacet[];
  rideBookers: RideBookerFacet[];
  transportationCompanies: TransporationCompaniesFacet[];
}

export interface RideList {
  currentPage: number;
  facets: RideListFacets;
  messages?: RoundtripApiMessage[];
  pageItems: number;
  pages: number;
  rides: Ride[];
  totalCount: number;
}

export type RideStatusQueryParams =
  | { availableStatus: AvailableStatus[] }
  | { assignedStatus: AssignedStatus[] }
  | { statusBadge: RideStatusBadge[] };

export interface TransportationCompany {
  id: number;
  name: string;
  zipcode: string;
}

export interface Schedule {
  count: number;
  id: number;
  lastRideDate: string;
}

export interface Trip {
  id: number;
  scheduleId?: number;
}

type FlashTypes = 'success' | 'alert' | 'error' | 'warn';

/**
 * The shape of flash messages passed into react components from the server.
 */
export type RubyFlashMessage = [FlashTypes, string];

/**
 * The shape of flash messages after storing them into the store.
 */
export interface IFlashMesssage {
  text: string;
  type: FlashTypes;
}

export type ToastActions = 'refresh' | 'view ride' | 'success';

export interface ToastNotification {
  action?: ToastActions;
  rideId?: number;
  role?: Role;
  text: string;
}

export interface DeleteRide {
  body: {
    additionalCancelationInfo: string;
    cancelationReason: string;
    id: string | number;
    scheduleId?: number;
    sendNotifications: boolean;
    tripId?: string | number;
  };
  rideId: number;
}

export interface SubmitRideResponse {
  additionalInfo?: string;
  declinedAt?: Date;
  eta?: Date;
  reason?: string;
  rideId?: number;
}

export interface RideEtaResponses {
  id: number;
}

export interface UnsafeSSRData {
  global: {
    currentUser: User;
    environment: 'development' | 'production' | 'test';
    flash: RubyFlashMessage[];
    isDriver: boolean;
    token?: string | null;
    transportationCompanyId: number;
    userMetadata: {
      canBookTrip: boolean;
    };
  };
}

export type GlobalSSRData = Window &
  typeof globalThis & { _unsafeSSRData: UnsafeSSRData };

export type UserPreferencesPages = {
  admin: 'scheduled';
  careCoordinator: 'scheduled';
  dispatcher: 'community' | 'assigned';
  superUser: 'scheduled';
};

export interface UserColumnPreferences {
  userPreference: {
    dashboardColumnPreferences: {
      [r in keyof typeof ROLES]?: {
        [p in UserPreferencesPages[r]]?: {
          columnsToExcludeFromRendering?: ExcludibleColumn | null;
        };
      };
    };
    id?: number;
  };
}
declare global {
  interface Window {
    _unsafeSSRData: UnsafeSSRData;
  }
}
