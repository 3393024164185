/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useDeleteRideMutation } from '@/api';
import * as AlertDialog from '@/lib/@radix-ui/react-alert-dialog';
import { pushFlashMessage } from '@/store/flashMessagesSlice';

import './main.scss';

import type { GlobalSSRData, UnsafeSSRData } from '@/types';

interface RideCancel {
  cancellationReason: string;
  lastRideDate: string;
  navCCPresent: boolean;
  outboundDate: string;
  outboundTime: string;
  returnDate: string;
  returnTime: string;
  rideDirection: string;
  rideId: number;
  ridesCount: number;
  roundtrip: boolean;
  scheduleId: number;
  tripId: number;
}

type RideCancelSSRProps = UnsafeSSRData & { RideCancelModalLink: RideCancel };

const CANCEL_RIDE = 'Cancel ride';
const CANCEL_TRIP = 'Cancel trip';
const CANCEL_SERIES = 'Cancel series';

const SINGLE_RIDE = 'single_ride';
const ENTIRE_TRIP = 'entire_trip';
const ENTIRE_SERIES = 'entire_series';

const OPTIONS = {
  single_ride: CANCEL_RIDE,
  entire_trip: CANCEL_TRIP,
  entire_series: CANCEL_SERIES,
};

const RideCancelModalLink = () => {
  const {
    rideId,
    tripId,
    scheduleId,
    cancellationReason,
    outboundDate,
    outboundTime,
    returnDate,
    returnTime,
    lastRideDate,
    rideDirection,
    ridesCount,
    roundtrip,
    navCCPresent,
  } = ((window as GlobalSSRData)._unsafeSSRData as RideCancelSSRProps)
    .RideCancelModalLink;

  const [selectedOption, setSelectedOption] = useState(SINGLE_RIDE);
  const [selectedReason, setSelectedReason] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [buttonText, setButtonText] = useState(CANCEL_RIDE);
  const [cancellationReasonOn, setCancellationReasonOn] = useState(true);

  const dispatch = useDispatch();

  const [deleteRide] = useDeleteRideMutation();

  const handleReasonChange = (event) => setSelectedReason(event?.target?.value);
  const handleAdditionalInfoChange = (event) =>
    setAdditionalInfo(event.target.value);
  const toggleSwitch = () =>
    setCancellationReasonOn((prevSwitch) => !prevSwitch);

  const handleOptionChange = (event) => {
    const label = event.target;
    const htmlForValue: keyof typeof OPTIONS = label.getAttribute('for');

    setSelectedOption(htmlForValue);
    setButtonText(OPTIONS[htmlForValue] || '');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      additionalCancelationInfo: additionalInfo,
      cancelationReason: selectedReason,
      sendNotifications: cancellationReasonOn,
      id: rideId,
    };

    if (buttonText === CANCEL_TRIP) {
      body.trip_id = tripId;
    } else if (buttonText === CANCEL_SERIES) {
      body.schedule_id = scheduleId;
    }

    deleteRide({ body, rideId })
      .unwrap()
      .then(() => window.location.reload())
      .catch((error: Error) => {
        dispatch(
          pushFlashMessage({
            text: 'Sorry, this ride cannot be canceled at the moment. Please try again in a few minutes.',
            type: 'error',
          }),
        );
      });
  };

  return (
    <div className="d-inline">
      <AlertDialog.Root>
        <AlertDialog.Trigger asChild>
          <button className="heading-link -red px-0" type="button">
            Cancel
          </button>
        </AlertDialog.Trigger>

        <AlertDialog.Portal>
          <AlertDialog.Overlay className="alert-dialog-overlay" />

          <AlertDialog.Content className="alert-dialog-content -scroll">
            <AlertDialog.Title className="mb-6">
              Cancel this ride?
            </AlertDialog.Title>

            <form
              className="form p-0"
              id="cancel-ride"
              acceptCharset="UTF-8"
              method="delete"
              onSubmit={handleSubmit}
            >
              {(roundtrip || scheduleId) && (
                <>
                  <div className="d-flex tab-labels">
                    <div className="flex-1">
                      <label
                        role="button"
                        tabIndex={0}
                        htmlFor={SINGLE_RIDE}
                        className={`tab-label ${
                          selectedOption === SINGLE_RIDE ? 'selected' : ''
                        }`}
                        onMouseUp={handleOptionChange}
                      >
                        <input
                          type="radio"
                          id={SINGLE_RIDE}
                          name="cancellation_option"
                          className="tab-radio"
                          defaultChecked={selectedOption === SINGLE_RIDE}
                          value={SINGLE_RIDE}
                        />
                        Just this ride
                      </label>
                    </div>
                    {roundtrip && (
                      <div className="flex-1">
                        <label
                          role="button"
                          tabIndex={0}
                          htmlFor={ENTIRE_TRIP}
                          className={`tab-label ${
                            selectedOption === ENTIRE_TRIP ? 'selected' : ''
                          }`}
                          onMouseUp={handleOptionChange}
                        >
                          <input
                            type="radio"
                            id={ENTIRE_TRIP}
                            name="cancellation_option"
                            className="tab-radio"
                            defaultChecked={selectedOption === ENTIRE_TRIP}
                            value={ENTIRE_TRIP}
                          />
                          Entire trip
                        </label>
                      </div>
                    )}
                    {scheduleId !== null && (
                      <div className="flex-1">
                        <label
                          role="button"
                          tabIndex={0}
                          htmlFor={ENTIRE_SERIES}
                          className={`tab-label ${
                            selectedOption === ENTIRE_SERIES ? 'selected' : ''
                          }`}
                          onMouseUp={handleOptionChange}
                        >
                          <input
                            type="radio"
                            id={ENTIRE_SERIES}
                            name="cancellation_option"
                            className="tab-radio"
                            defaultChecked={selectedOption === ENTIRE_SERIES}
                            value={ENTIRE_SERIES}
                          />
                          Entire series
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="box p-4 mt-3">
                    {selectedOption === SINGLE_RIDE &&
                      rideDirection !== 'return' && (
                        <div className="single-ride-details">
                          <span className="ride-type">Ride detail</span> <br />
                          <span className="ride-time-date">
                            {outboundDate}
                            <br />
                            {outboundTime}
                          </span>
                        </div>
                      )}

                    {selectedOption === SINGLE_RIDE &&
                      rideDirection === 'return' && (
                        <div className="single-ride-details">
                          <span className="ride-type">Ride detail</span> <br />
                          <span className="ride-time-date">
                            {returnDate}
                            <br />
                            {returnTime}
                          </span>
                        </div>
                      )}
                    {roundtrip && selectedOption === ENTIRE_TRIP && (
                      <div className="d-flex">
                        <div className="flex-1">
                          <span className="ride-type">Outbound ride</span>{' '}
                          <br />
                          <span className="ride-time-date">
                            {outboundDate}
                          </span>{' '}
                          <br />
                          <span className="ride-time-date">{outboundTime}</span>
                        </div>
                        <div className="flex-1">
                          <span className="ride-type">Return ride</span> <br />
                          <span className="ride-time-date">
                            {returnDate}
                          </span>{' '}
                          <br />
                          <span className="ride-time-date">{returnTime}</span>
                        </div>
                      </div>
                    )}
                    {scheduleId && selectedOption === ENTIRE_SERIES && (
                      <div>
                        <span className="series-message">
                          This will cancel the following repeat series until
                        </span>{' '}
                        <span className="last-ride-date">{lastRideDate} </span>
                        <br />
                        <span className="last-ride-date">
                          Total rides: {ridesCount}
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className="field">
                <label htmlFor="cancelation_reason" className="label">
                  Reason <span className="required">*</span>
                </label>

                <div className="dropdown-wrapper">
                  <select
                    data-test="cancelation-reason"
                    id="cancelation_reason"
                    name="cancelation_reason"
                    value={selectedReason}
                    onChange={handleReasonChange}
                    className="dropdown"
                    required
                  >
                    <option value="">Select a reason</option>
                    {cancellationReason.map((reason, index) => (
                      <option key={index} value={reason[1]}>
                        {reason[0]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="field mt-1">
                <label
                  role="button"
                  tabIndex={0}
                  htmlFor="additional_cancelation_info"
                  className="label"
                >
                  Additional information
                </label>
                <textarea
                  id="additional_cancelation_info"
                  name="additional_cancelation_info"
                  value={additionalInfo}
                  onChange={handleAdditionalInfoChange}
                  className="input -tall"
                />
              </div>

              {navCCPresent && (
                <div className="switch-container d-flex flex-justify-between mb-6">
                  <p>Send cancellation notifications</p>

                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={cancellationReasonOn}
                      onChange={toggleSwitch}
                    />
                    <span className="slider" />
                  </label>
                </div>
              )}

              <div
                style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}
              >
                <AlertDialog.Cancel asChild>
                  <button type="button" className="button py-2 -inverted-blue">
                    Nevermind
                  </button>
                </AlertDialog.Cancel>

                <button
                  data-test="cancel-ride-submit-btn"
                  type="submit"
                  className="button py-2 -cancel"
                >
                  {buttonText}
                </button>
              </div>
            </form>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );
};

export default RideCancelModalLink;
