import type { CellComponent } from '@/features/RidesTable/types';

import TimeCell from './TimeCell';

const CanceledAtCell: CellComponent = ({ ride, href }) => (
  <TimeCell
    time={ride.canceledAt as string}
    href={typeof href === 'function' ? href(ride) : href}
    willCall={false}
    label="Canceled At"
    timeZone={ride.timezone}
  />
);

export default CanceledAtCell;
