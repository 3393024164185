/* eslint-disable import/prefer-default-export */
import { roundtripBaseApiV3 } from '../../lib/rtk-query';

interface UpdateRide {
  currentStatus: string;
  eventTimestamp: Date;
  id: number;
  outboundDateTime: Date;
  outboundTimeType: string;
  returnDateTime: Date;
  returnTimeType: string;
  userConfirmedAt: Date;
  willCall: string | null;
}

const filterUndefinedValues = (params: Partial<UpdateRide>) => {
  return Object.fromEntries(
    Object.entries(params).filter(([, value]) => value),
  );
};

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    updateRide: build.mutation<void, Partial<UpdateRide>>({
      query: (params: Partial<UpdateRide>) => ({
        url: `rides/${params.id}`,
        method: 'PUT',
        body: filterUndefinedValues(params),
      }),
    }),
  }),
});

export const { useUpdateRideMutation } = extendedApi;
