/* eslint-disable import/prefer-default-export */

import type { Ride } from '@/types';

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel, keysToSnake } from '@/src/util/CaseConvert';

interface GetRoundtripDetailsParams {
  statusBadge?: string;
  tripId: number;
}

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getRoundtripDetails: build.query({
      query: (params: GetRoundtripDetailsParams) => {
        return {
          url: `/rides`,
          method: 'GET',
          params: keysToSnake(params) as GetRoundtripDetailsParams,
        };
      },
      transformResponse: (response: { rides: Ride[] }) =>
        keysToCamel(response) as { rides: Ride[] },
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as Record<string, unknown>, // TODO: Type error response
    }),
  }),
});

export const { useGetRoundtripDetailsQuery } = extendedApi;