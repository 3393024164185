import type { CcRideStatusBadges } from '@/types';

import {
  CheckIcon,
  Cross2Icon,
  DotIcon,
  MinusIcon,
} from '@radix-ui/react-icons';

/* eslint-disable camelcase */
export const DISPATCHER_RIDE_STATUS_BADGE_TEXT = {
  assigned: 'Assigned',
  available: 'Available',
  canceled: 'Canceled',
  completed: 'Completed',
  declined: 'Declined',
  in_progress: 'In Progress',
  new_ride: 'New Ride',
  not_started: 'Not Started',
  preferred: 'Preferred',
  responded: 'Responded',
  submit_eta: 'Submit ETA',
  processing: 'Processing',
  will_call_assigned: 'Not Started',
  will_call_available: 'Not Started',
} as const;

export const CC_RIDE_STATUS_BADGE_TEXT = {
  canceled: 'Canceled',
  completed: 'Completed',
  brokered: 'Brokered',
  on_hold: 'On Hold',
  will_call: 'Will Call',
  incomplete: 'Incomplete',
  requested: 'Requested',
  finding_driver: 'Finding Driver',
  claimed: 'Claimed',
  finding_providers: 'Finding Providers',
  checking_availability: 'Checking Availability',
  at_pickup: 'At Pickup',
  to_pickup: 'To Pickup',
  at_destination: 'At Destination',
  to_destination: 'To Destination',
} as const;
/* eslint-enable camelcase */

export const COLORS = {
  deepRed: '-deep-red',
  green: '-green',
  grey: '-grey',
  orange: '-orange',
  newRide: '-yellow',
  preferred: '-aqua tooltip',
  processing: '-processing d-inline-flex',
  red: '-red',
  yellow: '-yellow',
} as const;

export type Colors = (typeof COLORS)[keyof typeof COLORS];

export const STYLES = {
  Assigned: COLORS.green,
  Available: COLORS.green,
  Canceled: COLORS.orange,
  Completed: COLORS.green,
  Declined: COLORS.deepRed,
  'In Progress': COLORS.green,
  'New Ride': COLORS.yellow,
  'Not Started': COLORS.grey,
  Preferred: COLORS.preferred,
  Processing: COLORS.processing,
  Responded: COLORS.grey,
  'Submit ETA': COLORS.orange,
} as const;

/* eslint-disable camelcase */
export const CC_STYLES = {
  canceled: COLORS.red,
  completed: COLORS.green,
  brokered: COLORS.yellow,
  on_hold: COLORS.orange,
  will_call: COLORS.grey,
  incomplete: COLORS.orange,
  requested: COLORS.green,
  finding_driver: COLORS.green,
  claimed: COLORS.green,
  finding_providers: COLORS.green,
  checking_availability: COLORS.green,
  at_pickup: COLORS.green,
  to_pickup: COLORS.green,
  at_destination: COLORS.green,
  to_destination: COLORS.green,
} as const;

type StatusBadgeIcons = {
  [key in CcRideStatusBadges]: typeof DotIcon | null;
};

export const CC_ICONS: StatusBadgeIcons = {
  canceled: null,
  completed: null,
  brokered: null,
  on_hold: null,
  will_call: null,
  incomplete: null,
  requested: null,
  finding_driver: null,
  claimed: null,
  finding_providers: null,
  checking_availability: null,
  at_pickup: null,
  to_pickup: null,
  at_destination: null,
  to_destination: null,
} as const;
/* eslint-enable camelcase */

export const ICONS = {
  Assigned: null,
  Available: DotIcon,
  Canceled: null,
  Completed: null,
  Declined: Cross2Icon,
  'In Progress': null,
  'New Ride': null,
  'Not Started': MinusIcon,
  Preferred: null,
  Processing: null,
  Responded: CheckIcon,
  'Submit ETA': null,
} as const;
