import React from 'react';

import type { RideColumn } from '@/features/RidesTable/types';
import type { AppDispatch } from '@/store';
import type {
  AuthStatus,
  CcRideStatusBadges,
  Entries,
  Ride,
  VehicleType,
} from '@/types';

import { Cross2Icon } from '@radix-ui/react-icons';
import { TRIP_TYPES } from '@/types';

import { useDispatch, useSelector } from 'react-redux';

import CancelationReasonCell from '@/components/RidesTable/CancelationReasonCell';
import CanceledAtCell from '@/components/RidesTable/CanceledAtCell';
import RideStatusBadgeCell from '@/components/RidesTable/careCoordinator/RideStatusBadgeCell';
import CompletedAtCell from '@/components/RidesTable/CompletedAtCell';
import DropoffLocationCell from '@/components/RidesTable/DropoffLocationCell';
import PatientCell from '@/components/RidesTable/PatientCell';
import PickupLocationCell from '@/components/RidesTable/PickupLocationCell';
import PickupTimeCell from '@/components/RidesTable/PickupTimeCell';
import VehicleTypeCell from '@/components/RidesTable/VehicleTypeCell';
import { useAuth } from '@/contexts/AuthProvider';
import Filters from '@/features/RidesTable/components/Filters';
import Header from '@/features/RidesTable/components/Header';
import Cell from '@/features/RidesTable/components/Table/Cell';
import TableHandler from '@/features/RidesTable/components/TableHandler';
import useUserPreferredColumns from '@/features/RidesTable/hooks/useUserPreferredColumns';
import {
  resetFilters,
  selectRideFilters,
  setAuthStatuses,
  setEndDate,
  setItemsPerPage,
  setPage,
  setSearch,
  setShowWillCall,
  setSortField,
  setSortType,
  setStartDate,
  setStatusBadge,
  setVehicleTypes,
  setTripTypes,
  setHospitals,
  setTxCompanies,
} from '@/features/RidesTable/store/cc/ridesFilterSlice';
import { CC_RIDE_STATUS_BADGE_TEXT } from '@/features/RideStatusBadge/types';
import {
  CC_RIDE_STATUS_BADGES,
  DEFAULT_CC_FILTER_STATUSES,
  VEHICLE_TYPES,
} from '@/types';
import { formatPhone } from '@/utils/format';

const {
  canceled: _canceled, // eslint-disable-line @typescript-eslint/no-unused-vars
  completed: _completed, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...statusDropdownItems
} = CC_RIDE_STATUS_BADGE_TEXT;

const BASE_COLUMNS: RideColumn[] = [
  {
    Cell: PatientCell,
    removeable: false,
    label: 'Rider Name',
    sortKey: 'rider_name',
    href: ({ id }) => `/admin/rides/${id}`,
  },
  {
    Cell: PickupLocationCell,
    label: 'Pickup',
    removeable: false,
    sortKey: 'pickup_location',
    hideKey: 'pickup_location',
    href: ({ id }) => `/admin/rides/${id}`,
  },
  {
    Cell: VehicleTypeCell,
    label: 'Vehicle Type',
    removeable: false,
    sortKey: 'vehicle_type',
    hideKey: 'vehicle',
    href: ({ id }) => `/admin/rides/${id}`,
  },

  {
    Cell: DropoffLocationCell,
    label: 'Drop-off',
    removeable: false,
    sortKey: 'dropoff_location',
    hideKey: 'dropoff_location',
    href: ({ id }) => `/admin/rides/${id}`,
  },
  {
    Cell: ({ ride, href, label }) => {
      return (
        <Cell
          label={label}
          tdClass="pickup-location"
          href={typeof href === 'function' ? href(ride) : href}
        >
          {ride.hospitalName ? (
            <>
              <div className="t-ellipsis" title={ride.hospitalName}>
                {ride.hospitalName}
              </div>
              <div>
                {ride.rideBookerName}
                {ride.rideBookerPhone &&
                  ` · ${formatPhone(ride.rideBookerPhone)}`}
              </div>
            </>
          ) : (
            <div>B2C Client</div>
          )}
        </Cell>
      );
    },
    label: 'Organization',
    removeable: false,
    sortKey: 'organization',
    href: ({ id }) => `/admin/rides/${id}`,
  },
  {
    Cell: ({ ride, href, label }) => {
      return (
        <Cell
          label={label}
          tdClass="pickup-location"
          href={typeof href === 'function' ? href(ride) : href}
        >
          {ride.transportationCompanyName && (
            <>
              <div
                className="t-ellipsis"
                title={ride.transportationCompanyName}
              >
                {ride.transportationCompanyName}
              </div>
              <div>
                {ride.transportationCompanyPhone &&
                  formatPhone(ride.transportationCompanyPhone)}
              </div>
            </>
          )}
        </Cell>
      );
    },
    label: 'Transport',
    removeable: false,
    sortKey: 'transport',
    href: ({ id }) => `/admin/rides/${id}`,
  },
];

const CANCELED_COLUMNS = [
  ...BASE_COLUMNS,
  {
    Cell: CanceledAtCell,
    label: 'Canceled At',
    removeable: false,
    sortKey: 'canceled_at',
    href: ({ id }: Ride) => `/admin/rides/${id}`,
  },
  {
    Cell: CancelationReasonCell,
    label: 'Cancel Reason',
    removeable: false,
    sortKey: 'cancel_reason',
    href: ({ id }: Ride) => `/admin/rides/${id}`,
  },
];

const COMPLETED_COLUMNS = [
  ...BASE_COLUMNS,
  {
    Cell: CompletedAtCell,
    label: 'Completed At',
    removeable: false,
    sortKey: 'completed_at',
    href: ({ id }: Ride) => `/admin/rides/${id}`,
  },
  {
    Cell: RideStatusBadgeCell,
    label: 'Status',
    removeable: false,
    sortKey: 'current_status',
    href: ({ id }: Ride) => `/admin/rides/${id}`,
  },
];

const SCHEDULED_COLUMNS = [
  ...BASE_COLUMNS,
  {
    Cell: PickupTimeCell,
    label: 'Pickup Time',
    removeable: false,
    sortKey: 'start_time',
    href: ({ id }: Ride) => `/admin/rides/${id}`,
  },
  {
    Cell: RideStatusBadgeCell,
    label: 'Status',
    removeable: false,
    sortKey: 'current_status',
    href: ({ id }: Ride) => `/admin/rides/${id}`,
  },
];

const vehicleList = Object.entries(VEHICLE_TYPES) as Entries<
  typeof VEHICLE_TYPES
>;

const Scheduled = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useAuth();
  const filters = useSelector(selectRideFilters);
  const setPaginationPage = (page: number) => dispatch(setPage({ page }));

  const onStatusBtnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    statuses: CcRideStatusBadges[],
  ) => {
    e.preventDefault();
    dispatch(setStatusBadge(statuses));
  };

  const completedIsActive =
    filters.statusBadge.length === 1 &&
    filters.statusBadge[0] === CC_RIDE_STATUS_BADGES.completed;

  const canceledIsActive =
    filters.statusBadge.length === 1 &&
    filters.statusBadge[0] === CC_RIDE_STATUS_BADGES.canceled;

  let columns = [];

  if (canceledIsActive) {
    columns = CANCELED_COLUMNS;
  } else if (completedIsActive) {
    columns = COMPLETED_COLUMNS;
  } else {
    columns = SCHEDULED_COLUMNS;
  }

  const userPreferredColumns = useUserPreferredColumns(
    columns,
    currentUser.role,
    'scheduled',
  );

  return (
    <>
      <Header.Root>
        <div className="d-block d-sm-flex" style={{ gap: '0.25rem' }}>
          <Header.RangePicker
            endTime={filters.endTime}
            startTime={filters.startTime}
            setStartTime={(date: string) => dispatch(setStartDate(date))}
            setEndTime={(date: string) => dispatch(setEndDate(date))}
          />
          <Header.Search
            search={filters.search}
            setSearch={(input: string) => dispatch(setSearch(input))}
          />
        </div>

        <Header.StatusContainer>
          <Header.StatusButton
            active={!(completedIsActive || canceledIsActive)}
            onClick={(e) => onStatusBtnClick(e, DEFAULT_CC_FILTER_STATUSES)}
            status={{
              statusBadge: DEFAULT_CC_FILTER_STATUSES,
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Scheduled
          </Header.StatusButton>
          <Header.StatusButton
            active={completedIsActive}
            onClick={(e) =>
              onStatusBtnClick(e, [CC_RIDE_STATUS_BADGES.completed])
            }
            status={{
              statusBadge: [CC_RIDE_STATUS_BADGES.completed],
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Completed
          </Header.StatusButton>

          <Header.StatusButton
            active={canceledIsActive}
            onClick={(e) =>
              onStatusBtnClick(e, [CC_RIDE_STATUS_BADGES.canceled])
            }
            status={{
              statusBadge: [CC_RIDE_STATUS_BADGES.canceled],
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Canceled
          </Header.StatusButton>
        </Header.StatusContainer>
      </Header.Root>

      <TableHandler
        columns={userPreferredColumns}
        filters={filters}
        setPage={setPaginationPage}
        rowsPerPage={filters.items}
        onChangeRowsPerPage={(selected: number) =>
          dispatch(setItemsPerPage(selected))
        }
        sortType={filters.sortType}
        onChangeSortType={(type: string | null) => dispatch(setSortType(type))}
        sortField={filters.sortField}
        onChangeSortField={(field: string) => dispatch(setSortField(field))}
      >
        <Filters.Root>
          <div className="d-flex mb-3 mb-sm-0" style={{ gap: '0.25rem' }}>
            <Filters.VehicleType
              options={vehicleList}
              types={filters.vehicleTypes}
              setTypes={(types: VehicleType[]) =>
                dispatch(setVehicleTypes(types))
              }
            />
            {!(completedIsActive || canceledIsActive) && (
              <Filters.Status
                defaultStatuses={DEFAULT_CC_FILTER_STATUSES}
                currentlySelected={filters.statusBadge}
                items={statusDropdownItems}
                onClose={(selected) =>
                  dispatch(setStatusBadge(selected as CcRideStatusBadges[]))
                }
              />
            )}
            <Filters.RequestingFacility
              setHospitals={(types: number[]) => dispatch(setHospitals(types))}
              isInit={filters.isInit}
            />
            <Filters.TransportationCompany
              setTxCompanies={(types: number[]) =>
                dispatch(setTxCompanies(types))
              }
              isInit={filters.isInit}
            />
            <Filters.AuthStatus
              statuses={filters.authStatuses}
              setStatuses={(types: AuthStatus[]) =>
                dispatch(setAuthStatuses(types))
              }
            />
            <Filters.WillCall
              willCall={filters.willCall}
              setWillCall={(wc: boolean | null) =>
                dispatch(setShowWillCall(wc))
              }
            />
            <Filters.TripType
              options={Object.entries(TRIP_TYPES) as Entries<typeof TRIP_TYPES>}
              types={filters.tripTypes}
              setTypes={(types: string[]) => dispatch(setTripTypes(types))}
            />
            <Filters.ClearFilters
              visible={!filters.isInit}
              reset={() => dispatch(resetFilters())}
              className="t-grey-50"
            >
              <Cross2Icon />
            </Filters.ClearFilters>
          </div>
          <Filters.EditColumns
            columns={[
              { text: 'Pickup Location', key: 'pickup_location' },
              { text: 'Dropoff Location', key: 'dropoff_location' },
            ]}
            userRole="admin"
            page="scheduled"
          />
        </Filters.Root>
      </TableHandler>
    </>
  );
};

export default Scheduled;
