import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import type { Entries } from '@/types';

import { ChevronDownIcon } from '@radix-ui/react-icons';
import * as Popover from '@/lib/@radix-ui/react-popover';
import CheckboxItem from './CheckboxItem';
import { TRIP_TYPES } from '@/types';

const TRIP_DISPLAY_NAMES = {
  one_way: 'One Way',
  round_trip: 'Roundtrip',
};

const filterLabel = 'Trip type';

const getTripDisplayText = (value: string) => {
  const lookupKey = (
    Object.keys(TRIP_TYPES) as (keyof typeof TRIP_TYPES)[]
  ).find((key) => TRIP_TYPES[key] === value);
  return TRIP_DISPLAY_NAMES[lookupKey as keyof typeof TRIP_DISPLAY_NAMES];
};

const getFilterLabel = (
  tripTypes: string[],
  tripTypesList: Entries<typeof TRIP_TYPES>,
) => {
  if (tripTypesList.length === tripTypes.length) {
    return filterLabel;
  }
  if (tripTypes.length === 1) {
    return `${filterLabel}: ${getTripDisplayText(tripTypes[0])}`;
  }
  return `${filterLabel}: Multiple`;
};

const TripType: React.FC<{
  options: Entries<typeof TRIP_TYPES>;
  types: string[];
  setTypes: (types: string[]) => void;
}> = ({ options, types, setTypes }) => {
  const [updatedTripTypes, setUpdatedTripTypes] = useState(types);

  const label = useMemo(() => getFilterLabel(types, options), [types, options]);

  // Keep local state in sync with parent updates.
  useEffect(() => setUpdatedTripTypes(types), [types]);

  const handleClose = () => {
    if (types !== updatedTripTypes) {
      setTypes(updatedTripTypes);
    }
  };

  const handleCheck = (tripType: string) => {
    const isPresent = updatedTripTypes.includes(tripType);
    if (isPresent) {
      setUpdatedTripTypes(updatedTripTypes.filter((t) => t !== tripType));
    } else {
      setUpdatedTripTypes([...updatedTripTypes, tripType]);
    }
  };

  return (
    <div>
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {label}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content px-0 py-3"
            align="start"
            onCloseAutoFocus={handleClose}
          >
            <>

              <div className="px-4">
                {options.map(([key, value]) => (
                  <CheckboxItem
                    key={key}
                    text={TRIP_DISPLAY_NAMES[key]}
                    checked={updatedTripTypes.includes(value)}
                    onCheckedChange={() => handleCheck(value)}
                  />
                ))}
              </div>
            </>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default TripType;
