/* eslint-disable import/prefer-default-export */

import type { Ride } from '@/types';

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel } from '@/src/util/CaseConvert';

interface GetScheduledRidesParams {
  currentStatus?: string[];
  scheduleId: number;
}

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getScheduledRides: build.query({
      query: ({ scheduleId, currentStatus = [] }: GetScheduledRidesParams) => {
        return {
          url: `/rides`,
          method: 'GET',
          params: {
            schedule_id: scheduleId,
            current_status: currentStatus,
            sort_field: 'start_time',
            sort_type: 'ASC',
            items: 60,
          },
        };
      },
      transformResponse: (response: { rides: Ride[] }) =>
        keysToCamel(response) as { rides: Ride[] },
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as Record<string, unknown>, // TODO: Type error response
    }),
  }),
});

export const { useGetScheduledRidesQuery } = extendedApi;
