import { useEffect, useMemo, useState } from 'react';

import type { CellComponent } from '@/features/RidesTable/types';
import type { Dayjs } from '@/lib/dayjs';
import type { DispatcherRideStatusBadge, Ride } from '@/types';

import { ClockIcon } from '@radix-ui/react-icons';

import Cell from '@/features/RidesTable/components/Table/Cell';
import RideStatusBadge from '@/features/RideStatusBadge';
import {
  DISPATCHER_RIDE_STATUS_BADGE_TEXT,
  ICONS,
  STYLES,
} from '@/features/RideStatusBadge/types';
import { useInterval } from '@/hooks/useInterval';
import dayjs from '@/lib/dayjs';
import { TIMELINESS } from '@/types';

const DELAY_IN_MS = 1000;
const ZERO_TIME_REMAINING = '0:00 left';

export const remainingTime = (cutoff: Dayjs) =>
  cutoff.diff(dayjs.utc(), 'second');

export const withinWindow = (cutoff: Dayjs) =>
  cutoff.isValid() && remainingTime(cutoff) > 0;

export const humanizedTimeliness = ({ timeliness }: Ride) => {
  switch (timeliness) {
    case TIMELINESS.on_time:
      return 'On time';
    case TIMELINESS.late_15_min:
      return 'Late 15 min';
    case TIMELINESS.late_30_min:
      return 'Late 30 min';
    case TIMELINESS.late_45_min:
      return 'Late 45 min';
    case TIMELINESS.late_60_min:
      return 'Late 60 min';
    case TIMELINESS.late_over_60_min:
      return 'Late over 60 min';
    default:
      return 'On time';
  }
};

export const doubleDigits = (num: number) =>
  num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

export const formatDisplay = (seconds: number) =>
  `${Math.floor(seconds / 60)}:${doubleDigits(seconds % 60)} left`;

const RideStatusBadgeCell: CellComponent = ({ ride }) => {
  const [timeRemaining, setTimeRemaining] = useState<string | null>(null);
  const isRunning =
    timeRemaining !== null && timeRemaining !== ZERO_TIME_REMAINING;

  const { name, biddingWindowCutoff, ppCutoffTime } = useMemo(
    () => ({
      name: ride.riderFullName.split(' ').join('-').toLowerCase(),
      biddingWindowCutoff: dayjs
        .utc(ride.communityArrival)
        .add(ride.biddingWindow, 'second'),
      ppCutoffTime: dayjs
        .utc(ride.preferredProviderArrival)
        .add(ride.ppBiddingWindow, 'second'),
    }),
    [ride],
  );

  const setFormattedDisplay = (cutoff: Dayjs) =>
    setTimeRemaining(formatDisplay(remainingTime(cutoff)));

  const setAfterTickValues = () => {
    if (!withinWindow(biddingWindowCutoff)) {
      setTimeRemaining(ZERO_TIME_REMAINING);
    }

    if (withinWindow(ppCutoffTime)) {
      setFormattedDisplay(ppCutoffTime);
    } else if (withinWindow(biddingWindowCutoff)) {
      setFormattedDisplay(biddingWindowCutoff);
    }
  };

  useInterval(() => setAfterTickValues(), isRunning ? DELAY_IN_MS : null);

  useEffect(() => {
    if (withinWindow(ppCutoffTime)) {
      setFormattedDisplay(ppCutoffTime);
    } else if (withinWindow(biddingWindowCutoff)) {
      setFormattedDisplay(biddingWindowCutoff);
    }
  }, [biddingWindowCutoff, ppCutoffTime, ride]);

  let badgeText =
    DISPATCHER_RIDE_STATUS_BADGE_TEXT[
      ride.statusBadge as DispatcherRideStatusBadge
    ];

  /**
   * When true, the current status badge is ignored and `processing` is displayed.
   *
   * There is a delay between 0-60 seconds that happens between when a rides timer hits 0
   * and when the server runs the job to dertermin the winner. When the timer hits 0,
   * `processing` should display for that duration. A WS event will update the ride after
   * a winner has been determined.
   */
  if (timeRemaining === ZERO_TIME_REMAINING) {
    badgeText = DISPATCHER_RIDE_STATUS_BADGE_TEXT.processing;
  }

  const style = STYLES[badgeText];
  const Icon = ICONS[badgeText];
  let SubIcon = null;
  let subtext = null;

  if (timeRemaining && timeRemaining !== ZERO_TIME_REMAINING) {
    subtext = timeRemaining;
    SubIcon = ClockIcon;
  } else if (badgeText === DISPATCHER_RIDE_STATUS_BADGE_TEXT.in_progress) {
    subtext = humanizedTimeliness(ride);
  }

  const displayTooltip =
    // Do not show tooltip if the user has already responded.
    badgeText === DISPATCHER_RIDE_STATUS_BADGE_TEXT.preferred &&
    withinWindow(ppCutoffTime);
  const displaySpinner =
    badgeText === DISPATCHER_RIDE_STATUS_BADGE_TEXT.processing;

  return (
    <Cell
      label="Status"
      tdClass="time-remaining -overflow"
      href={`/dispatcher/ride/${ride.id}`}
      anchorClass="py-8"
    >
      <RideStatusBadge.Root color={style}>
        <div className="d-flex">
          {displaySpinner && (
            <div className="loader-spin" data-testid="loader-spin" />
          )}

          <RideStatusBadge.Content id={`${name}-bid-status-message`}>
            {Icon && <Icon />}
            {badgeText}
          </RideStatusBadge.Content>

          {displayTooltip && (
            <RideStatusBadge.Tooltip>
              You can claim this trip prior to others in the area for a set
              period of time.
            </RideStatusBadge.Tooltip>
          )}
        </div>
        {subtext && (
          <RideStatusBadge.Subtext>
            {SubIcon && <SubIcon />} {subtext}
          </RideStatusBadge.Subtext>
        )}
      </RideStatusBadge.Root>
    </Cell>
  );
};

export default RideStatusBadgeCell;
