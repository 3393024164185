import * as React from 'react';

import AuthStatus from './AuthStatus';
import ClearFilters from './Clear';
import Distance from './Distance';
import EditColumns from './EditColumns';
import RequestingFacility from './RequestingFacilities';
import RideBookers from './RideBooker';
import Status from './Status';
import VehicleType from './VehicleType';
import WillCall from './WillCall';
import TripType from './TripType';
import TransportationCompany from './TransportationCompany';

const Root: React.FC<{
  children: React.ReactNode;
  variant?: 'inline' | 'block';
  visible?: boolean;
}> = ({ children, visible = true, variant = 'block' }) => {
  const classes =
    variant === 'inline'
      ? 'd-flex'
      : `${visible ? `d-flex` : `d-none`} flex-column flex-sm-row flex-justify-between mb-4`;

  return (
    <div className={classes} style={{ overflowX: 'auto' }}>
      {children}
    </div>
  );
};

const Filters = {
  AuthStatus,
  Root,
  ClearFilters,
  Distance,
  RideBookers,
  RequestingFacility,
  Status,
  VehicleType,
  WillCall,
  EditColumns,
  TripType,
  TransportationCompany
};

export default Filters;
