/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import * as React from 'react';

import type { Facet } from '@/types';

import InfoIcon from '@assets/images/icons/Info.svg';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { useDispatch } from 'react-redux';

import Separator from '@/components/Separator';
import * as Popover from '@/lib/@radix-ui/react-popover';
import * as Tooltip from '@/lib/@radix-ui/react-tooltip';
import isEmpty from '@/utils/isEmpty';

import { useTableContext } from '../../providers/TableProvider';
import CheckboxItem from './CheckboxItem';

const filterLabel = 'Transportation Company';

const getSelectedTxCompanies = (
  txCompanyList: Facet[],
  txIds: {
    [key: number]: number | null;
  },
) => txCompanyList.filter(({ id }) => !txIds[id]).map(({ id }) => id);

const getFilterLabel = (txCompanyList: Facet[], selectedTxCompanies: number[]) => {
  if (selectedTxCompanies.length === txCompanyList.length) {
    return filterLabel;
  }
  if (selectedTxCompanies.length === 1) {
    return `${filterLabel}: 1 company`;
  }

  return `${filterLabel}: ${selectedTxCompanies.length} companies`;
};

const TransportationCompany = ({ setTxCompanies, isInit }: { setTxCompanies: (txCompanies: number[]) => void, isInit: boolean }) => {
  const dispatch = useDispatch();
  // const isInit = useSelector(selectIsInit);
  const { transportationCompanies } = useTableContext();
  // An object representing the DESELECTED tx companies
  // Selected tx companies are absent from the object or have a null value i.e. { 1: null }.
  const [txIds, setTxIds] = useState<{
    [key: number]: number | null;
  }>({});

  const [txCompanyList, setTxCompanyList] = useState(transportationCompanies);
  const [txCompanySearchText, setTxCompanySearchText] = useState('');

  // State to track if the input is focused
  const [isFocused, setIsFocused] = useState(false);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isInit) {
      setTxIds({});
    }
  }, [isInit]);

  useEffect(() => {
    setTxCompanyList(transportationCompanies);
  }, [transportationCompanies]);

  // This is a workaround to ensure that the tx company list is cleared when the page changes (e.g. from Rides to Community).
  useEffect(() => {
    dispatch(setTxCompanies([]));
  }, [dispatch]);

  const handleApply = () => {
    const selectedTxCompanies = getSelectedTxCompanies(txCompanyList, txIds);

    /**
     * `ridesFilterSlice` checks if `transportationCompanies` is an empty array when determining
     * the `isInit` value. If a user selects a checkbox item and then deselects it,
     * set the filter state to an empty array instead of the  full list.
     */
    const txCompany =
      selectedTxCompanies.length === txCompanyList.length ? [] : selectedTxCompanies;

    dispatch(setTxCompanies(txCompany));
    setOpen(false);
  };


  const handleCheck = (id: number) => {
    setTxIds({
      ...txIds,
      [id]: txIds[id] ? null : id,
    });
  };

  const handleSelectAll = () => {
    // If everything is currently selected (empty txIds),
    // deselect all by adding all txCompany IDs to the object
    if (isEmpty(txIds)) {
      const allDeselected = txCompanyList.reduce(
        (acc, txCompany) => {
          acc[txCompany.id] = txCompany.id;
          return acc;
        },
        {} as { [key: number]: number },
      );
      setTxIds(allDeselected);
    } else {
      // Otherwise, select all by clearing the txIds object
      setTxIds({});
    }
  };

  const filterTransportationCompanyList = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxCompanySearchText(event.target.value);
    if (
      event.target.value === null ||
      event.target.value === undefined ||
      event.target.value.trim() === ''
    ) {
      setTxCompanyList(transportationCompanies);
    } else {
      setTxCompanyList(
        transportationCompanies.filter((txCompany) =>
          txCompany.name
            .toLocaleUpperCase()
            .includes(event.target.value.toLocaleUpperCase()),
        ),
      );
    }
  };

  return (
    <div className="">
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {getFilterLabel(
              txCompanyList,
              getSelectedTxCompanies(txCompanyList, txIds),
            )}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content className="popover-content py-3 px-0" align="start">
            <div className="popover-top d-flex px-4 flex-column mb-0">
              <label className="popover-title" htmlFor="facility-search">
                <div
                  className="d-flex flex-items-center"
                  style={{ gap: '0.25rem' }}
                >
                  Filter companies
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div className="IconButton">
                          <InfoIcon />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent"
                          sideOffset={2}
                        >
                          List displays transportation companies associated to rides.
                          <Tooltip.Arrow className="TooltipArrow" />
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
              </label>
              <div className={`styled-input ${isFocused ? 'focused' : ''}`}>
                <div className="d-flex pl-2">
                  <MagnifyingGlassIcon />
                </div>
                <div className="d-flex flex-1 flex-self-stretch">
                  <input
                    placeholder="Find by name"
                    className="styled-input__field"
                    id="facility-search"
                    onChange={filterTransportationCompanyList}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    value={txCompanySearchText}
                  />
                </div>
              </div>
            </div>
            <div className="popover-scrollist ">
              {txCompanyList.length > 0 ? (
                <>
                  {/* Only show Select All when there are 2 or more transportation companies */}
                  {txCompanyList.length > 1 && (
                    <>
                      <div className="py-3 px-4">
                        <CheckboxItem
                          text={
                            isEmpty(txIds) ? 'Deselect all' : 'Select all'
                          }
                          key="select-all"
                          checked={isEmpty(txIds)}
                          onCheckedChange={handleSelectAll}
                        />
                      </div>

                      <Separator />
                    </>
                  )}

                  <div className="py-3 px-4">
                    {txCompanyList.map((txCompany) => (
                      <CheckboxItem
                        text={txCompany.name}
                        key={txCompany.id}
                        onCheckedChange={() => handleCheck(txCompany.id)}
                        checked={!txIds[txCompany.id]}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className="empty-state py-3 py-4 -no-border">
                  No results found.
                </div>
              )}
            </div>

            <div className="popover-actions d-flex flex-justify-end px-4 mt-0">
              <button
                className="button -small -no-background"
                onClick={() => setOpen(false)}
                type="button"
              >
                Cancel
              </button>
              <button
                className="button -small -primary"
                onClick={handleApply}
                type="button"
              >
                Apply
              </button>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default TransportationCompany;
