import { useState } from 'react';

import type { CellComponent } from '@/features/RidesTable/types';
import type { StreamableRide } from '@/types';

import { DotsVerticalIcon } from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { CC_RIDE_STATUS_BADGES, RIDE_TYPES, VEHICLE_TYPES } from '@/types';

import CancelTripModal from './CancelTripModal/index';
import ChangeRideTimeModal from './ChangeRideTimeModal';
import ConfirmPickupDropoffModal from './ConfirmPickupDropoffModal';
import RedispatchRideModal from './RedispatchRideModal';

import '../main.scss';
import './main.css';

import dayjs from '@/lib/dayjs';

const showConfirmationModal = (ride: StreamableRide) => {
  const startOfYesterday = dayjs
    .utc()
    .subtract(1, 'day')
    .startOf('day')
    .tz(ride.timezone);
  const endOfTomorrow = dayjs
    .utc()
    .add(1, 'day')
    .endOf('day')
    .tz(ride.timezone);
  const formattedRideTime = dayjs
    .utc(ride.startTimeAwardedBid)
    .tz(ride.timezone);

  const currentTime = dayjs.utc().tz(ride.timezone);

  const isCurrentTimeAfter = currentTime.isAfter(formattedRideTime);

  const isBetweenDates = formattedRideTime.isBetween(
    startOfYesterday,
    endOfTomorrow,
  );

  return (
    !ride.usesCustomizeDispatchSoftware &&
    ride.vehicleType !== VEHICLE_TYPES.rideshare &&
    !ride.droppedOffAt &&
    ride.claimed &&
    isCurrentTimeAfter &&
    isBetweenDates
  );
};

const QuickActionCellCareCoordinator: CellComponent = ({ label, ride }) => {
  const { reactDashboardQuickActionsCc = false } = useFlags();

  const [showCancelTripModal, setShowCancelTripModal] = useState(false);
  const [showChangeRideTimeModal, setShowChangeRideTimeModal] = useState(false);
  const [showConfirmPickupDropoffModal, setShowConfirmPickupDropoffModal] =
    useState(false);
  const [showRedispatchRideModal, setShowRedispatchRideModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const isWillCall = ride.statusBadge === CC_RIDE_STATUS_BADGES.willCall;
  const isCancelled = ride.statusBadge === CC_RIDE_STATUS_BADGES.canceled;
  const confirmDropoff = ride.dropoffTime;
  const isCompleted = ride.statusBadge === CC_RIDE_STATUS_BADGES.completed;
  const isRideShare = ride.rideshare;

  const rideInProgress =
    ride.statusBadge === CC_RIDE_STATUS_BADGES.atPickup ||
    ride.statusBadge === CC_RIDE_STATUS_BADGES.toPickup ||
    ride.statusBadge === CC_RIDE_STATUS_BADGES.atDestination ||
    ride.statusBadge === CC_RIDE_STATUS_BADGES.toDestination ||
    ride.statusBadge === CC_RIDE_STATUS_BADGES.findingDriver;

  const showQuickActions =
    (!isCancelled || isRideShare) &&
    (!isCompleted || showConfirmationModal(ride)) &&
    !(isRideShare && rideInProgress);

  return (
    <td className="quick-action" data-label={label} aria-label={label}>
      {showCancelTripModal && (
        <CancelTripModal
          hide={() => setShowCancelTripModal(false)}
          show={showCancelTripModal}
          rideTime={ride.startTime}
          rideId={ride.id}
          tripId={ride.tripId}
          roundtrip={ride.rideType === RIDE_TYPES.roundTrip}
          navCCPresent={false}
          otherRideId={ride.otherRideId}
          rideTimezone={ride.timezone}
        />
      )}
      {showChangeRideTimeModal && (
        <ChangeRideTimeModal
          hide={() => setShowChangeRideTimeModal(false)}
          show={showChangeRideTimeModal}
          rideId={ride.id}
          timezone={ride.timezone}
          rideStartTime={new Date(ride.startTime)}
          pickupStreetAddress={ride.pickupLocation.streetAddress}
          dropoffStreetAddress={ride.dropoffLocation.streetAddress}
          direction={ride.direction}
          willCall={isWillCall}
        />
      )}
      {showConfirmPickupDropoffModal && (
        <ConfirmPickupDropoffModal
          rideStartTime={new Date(ride.startTime)}
          hide={() => setShowConfirmPickupDropoffModal(false)}
          show={showConfirmPickupDropoffModal}
          rideId={ride.id}
          timezone={ride.timezone}
          isPickup={!confirmDropoff}
        />
      )}
      {showRedispatchRideModal && (
        <RedispatchRideModal
          hide={() => setShowRedispatchRideModal(false)}
          show={showRedispatchRideModal}
          rideId={ride.id}
        />
      )}

      {reactDashboardQuickActionsCc && (
        <div className="quick-action">
          <Popover.Root open={openDropdown} onOpenChange={setOpenDropdown}>
            {showQuickActions && (
              <Popover.Trigger asChild>
                <button
                  className="IconButton"
                  aria-label="Customise options"
                  type="button"
                >
                  <DotsVerticalIcon />
                </button>
              </Popover.Trigger>
            )}

            <Popover.Portal>
              <Popover.Content
                className="popover-content"
                align="end"
                sideOffset={5}
              >
                <div className="d-flex flex-column" style={{ gap: 10 }}>
                  {!isCancelled && (
                    <>
                      {showConfirmationModal(ride) && (
                        <button
                          type="button"
                          className="quick-action-option"
                          onClick={() => {
                            setOpenDropdown(false);
                            setShowConfirmPickupDropoffModal(true);
                          }}
                        >
                          {' '}
                          {!confirmDropoff
                            ? 'Confirm pickup'
                            : 'Confirm drop-off'}
                        </button>
                      )}
                      {!isCompleted && (
                        <>
                          <button
                            type="button"
                            className="quick-action-option"
                            onClick={() => {
                              setOpenDropdown(false);
                              setShowChangeRideTimeModal(true);
                            }}
                          >
                            {isWillCall ? 'Set ride time' : 'Change ride time'}
                          </button>
                          <button
                            type="button"
                            className="quick-action-option"
                            onClick={() => {
                              window.location.href = `/book_trip/edit_trip/${ride.tripId}`;
                            }}
                          >
                            Edit trip
                          </button>
                          <button
                            type="button"
                            className="quick-action-option -negative"
                            onClick={() => {
                              setOpenDropdown(false);
                              setShowCancelTripModal(true);
                            }}
                          >
                            Cancel
                          </button>
                        </>
                      )}
                    </>
                  )}
                  {isCancelled && isRideShare && (
                    <button
                      type="button"
                      className="quick-action-option"
                      onClick={() => {
                        setOpenDropdown(false);
                        setShowRedispatchRideModal(true);
                      }}
                    >
                      Redispatch
                    </button>
                  )}
                </div>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
      )}
    </td>
  );
};

export default QuickActionCellCareCoordinator;
