import * as React from 'react';

import './main.scss';

import classNames from 'classnames';

const Separator: React.FC<React.ComponentProps<'div'>> = ({ className }) => {
  const classes = classNames('separator', className);

  return <div className={classes} />;
};

export default Separator;
