import * as Toast from '@/lib/@radix-ui/react-toast';

const handleViewRide = (rideId: number, role: string) => {
  if (role === 'dispatcher') {
    window.location.href = `/dispatcher/ride/${rideId}`;
  } else if (role === 'care_coordinator') {
    window.location.href = `/rides/${rideId}`;
  }
};

const ViewRideAction = (rideId: number, role: string) => (
  <Toast.Action className="ToastAction" asChild altText="Goto schedule to undo">
    <button
      type="button"
      className="Button small green"
      onClick={() => handleViewRide(rideId, role)}
    >
      View Ride
    </button>
  </Toast.Action>
);

export default ViewRideAction;
