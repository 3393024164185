import { useCallback, useEffect, useState } from 'react';

import type { Ride, Role, StreamableRide } from '@/types';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { RideFilterState } from '../types';

import { useDispatch } from 'react-redux';

import { useLazyGetRidesQuery } from '@/api';
import { useLazyGetRideQuery } from '@/api/rides/getRide';
import { useAuth } from '@/contexts/AuthProvider';
import * as logger from '@/lib/@datadog/browser-logs';
import { isCommunity, isScheduled, SCHEDULED_PATH } from '@/path_defs';
import {
  DEFAULT_CC_FILTER_STATUSES,
  DEFAULT_COMMUNITY_STATUSES,
  DEFAULT_SCHEDULED_STATUSES,
  ROLES,
} from '@/types';

import { setPage } from '../store/ridesFilterSlice';
import { setSelectedRides } from '../store/selectedRidesSlice';
import useWebsocket from './useWebsocket';

const defaultApiRes = {
  rides: [],
  facets: { hospitals: [], rideBookers: [], transportationCompanies: [] },
  totalCount: 0,
  pages: 0,
};

const getStatusBadges = (role: Role) => {
  const { pathname } = window.location;

  if (role === ROLES.dispatcher) {
    if (pathname === SCHEDULED_PATH) {
      return DEFAULT_SCHEDULED_STATUSES;
    }

    return DEFAULT_COMMUNITY_STATUSES;
  }

  if (role === ROLES.superUser || role === ROLES.careCoordinator) {
    return DEFAULT_CC_FILTER_STATUSES;
  }

  if (role === ROLES.admin) {
    return []; // TODO: Add admin status badges
  }

  logger.error(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `Invalid role or pathname for status badges: ${role} ${pathname}`,
  );
  return [];
};

const useRides = (filters: RideFilterState) => {
  const dispatch = useDispatch();

  const { currentUser } = useAuth();

  const dashboardWebsocket = useWebsocket(
    'DecoratedDashboardChannel',
    'dispatcher',
    currentUser.id,
  );

  const ridesWebsocket = useWebsocket(
    'DecoratedRidesChannel',
    'dispatcher',
    currentUser.id,
  );

  /**
   * Using a lazy query because refetch is set to always.
   *
   * WebSocket rides would cause a refetch.
   */
  const [
    getRidesQuery,
    {
      data: apiRides = defaultApiRes,
      isFetching,
      isError,
      error: rideFetchError,
    },
  ] = useLazyGetRidesQuery();

  const [getRideQuery] = useLazyGetRideQuery();

  /**
   * Local state so websockets can update the list.
   */
  const [rides, setRides] = useState<StreamableRide[]>([]);

  /**
   * Used to determine the number of pages for pagination.
   *
   * We cannot use `apiRides.totalCount` directly because that is the count of the last API request,
   * before rides have potentially been removed client-side,  nor can we use `apiRides.pages`
   * because it may no longer be correct.
   */
  const [totalCount, setTotalCount] = useState<number>(
    apiRides.totalCount || 0,
  );

  const pageLimit = filters.items;
  const currPage = filters.page;
  const finalPage = Math.ceil(totalCount / pageLimit);

  const getRides = useCallback(() => {
    setRides([]);
    return getRidesQuery(filters)
      .then((res) => {
        if (res.isSuccess) {
          setRides(res.data.rides || []);
          setTotalCount(res.data.totalCount);
        }

        return res;
      })
      .catch((e: unknown) => logger.error(JSON.stringify(e)));
  }, [filters, getRidesQuery]);

  const removeRideById = useCallback(
    (id: number) =>
      setRides((currRides) => {
        const filtered = currRides.filter((r) => {
          if (r.id === id) {
            setTotalCount((currCount) => currCount - 1);
            return false;
          }

          return true;
        });

        const listIsEmpty = filtered.length === 0;
        const serverSideRidesAvailable = totalCount > 1;
        const isOnLastPage = currPage === finalPage;

        if (listIsEmpty && serverSideRidesAvailable) {
          if (isOnLastPage) {
            dispatch(setPage({ page: currPage - 1 }));
          } else {
            getRides(); // eslint-disable-line @typescript-eslint/no-floating-promises
          }
        }

        return filtered;
      }),
    [currPage, dispatch, finalPage, getRides, totalCount],
  );

  const selectAllRides = () => {
    const rideIds = rides.map((ride) => ride.id);
    dispatch(setSelectedRides(rideIds));
  };

  const updateRideById = (id: number, params: Partial<StreamableRide>) => {
    setRides((curr) => {
      const idx = curr.findIndex((r) => r.id === id);

      // Do not _add_ new rides.
      if (idx === -1) {
        return curr;
      }

      const newRides = [...curr];

      newRides[idx] = {
        ...newRides[idx],
        ...params,
        updated: true,
      };

      return newRides;
    });
  };

  const getRide = useCallback(
    (ride: Ride) => {
      getRideQuery({
        rideId: ride.id,
        params: {
          statusBadge: getStatusBadges(currentUser.role),
        },
      })
        .unwrap()
        .then((incoming) => updateRideById(incoming.id, incoming))
        .catch((err: FetchBaseQueryError) => {
          if (err.status === 404 || err.status === 401) {
            removeRideById(ride.id);
            return;
          }

          if (typeof err.status === 'string') {
            logger.error(`Error fetching ride: ${err.error}`);
          } else {
            logger.error(`Error fetching ride: ${err.status}`);
          }
        });
    },
    [currentUser.role, getRideQuery, removeRideById],
  );

  const [messageHistory, setMessageHistory] = useState<number[]>([]);

  useEffect(() => {
    // Do not process messages if the user is a dispatcher.
    if (currentUser.role === ROLES.dispatcher) {
      return;
    }

    if (
      !dashboardWebsocket.lastJsonMessage?.message?.id ||
      !dashboardWebsocket.lastMessage?.timeStamp
    ) {
      return;
    }

    if (messageHistory.includes(dashboardWebsocket.lastMessage.timeStamp)) {
      return;
    }

    setMessageHistory([
      ...messageHistory,
      dashboardWebsocket.lastMessage.timeStamp,
    ]);

    /**
     * We currently send ride related WS events to users that shouldn't receive them.
     * This discards the event if the ride does not exist in the current rides list.
     */
    const idx = rides.findIndex(
      (r) => r.id === dashboardWebsocket.lastJsonMessage.message.id,
    );
    if (idx === -1) {
      return;
    }

    /**
     * TODO: Fix me!
     *
     * Data Race condition: The API server is broadcasting a ride to the client before
     * the transaction is committed. This results in a 404 for the client because the
     * ride is not available for them yet.
     *
     * Look at V2::BookTrip::CreateRequestProcessor#process
     *
     * This is a "temporary fix"™ to ensure the ride is fetched after the transaction is committed.
     */
    setTimeout(() => {
      getRide(dashboardWebsocket.lastJsonMessage.message);
    }, 5000);
  }, [
    currentUser.role,
    dashboardWebsocket.lastJsonMessage,
    getRide,
    dashboardWebsocket.lastMessage?.timeStamp,
    messageHistory,
    rides,
  ]);

  // Used to add new rides to the _community_ table when the v3 flag is off
  useEffect(() => {
    // Do not process messages if the user is not a dispatcher.
    if (currentUser.role !== ROLES.dispatcher) {
      return;
    }

    if (
      !ridesWebsocket.lastJsonMessage?.message?.id ||
      !ridesWebsocket.lastMessage?.timeStamp
    ) {
      return;
    }

    if (messageHistory.includes(ridesWebsocket.lastMessage.timeStamp)) {
      return;
    }

    setMessageHistory([
      ...messageHistory,
      ridesWebsocket.lastMessage.timeStamp,
    ]);

    /**
     * We currently send ride related WS events to users that shouldn't receive them.
     * This discards the event if the ride does not exist in the current rides list.
     */
    const idx = rides.findIndex(
      (r) => r.id === ridesWebsocket.lastJsonMessage.message.id,
    );
    if (idx !== -1) {
      // Skip this message if the ride already exists in the current rides list.
      return;
    }

    /**
     * TODO: Fix me!
     *
     * Data Race condition: The API server is broadcasting a ride to the client before
     * the transaction is committed. This results in a 404 for the client because the
     * ride is not available for them yet.
     *
     * Look at V2::BookTrip::CreateRequestProcessor#process
     *
     * This is a "temporary fix"™ to ensure the ride is fetched after the transaction is committed.
     */
    setTimeout(() => {
      getRideQuery({
        rideId: ridesWebsocket.lastJsonMessage.message.id,
        params: {
          statusBadge: getStatusBadges(currentUser.role),
        },
      })
        .unwrap()
        .then((incoming) => {
          setRides((ridesList) => {
            /**
             * Prevent auto assigned rides from showing up in the community
             */
            if (incoming.autoAssigned && isScheduled()) {
              setTotalCount((currCount) => currCount + 1);
              return [{ ...incoming, streamed: true }, ...ridesList];
            }

            if (isCommunity()) {
              setTotalCount((currCount) => currCount + 1);

              return [{ ...incoming, streamed: true }, ...ridesList];
            }

            return ridesList;
          });
        })
        .catch((err: FetchBaseQueryError) => {
          if (err.status === 404) {
            removeRideById(ridesWebsocket.lastJsonMessage.message.id);
            return;
          }

          if (typeof err.status === 'string') {
            logger.error(`Error fetching ride: ${err.error}`);
          } else {
            logger.error(`Error fetching ride: ${err.status}`);
          }
        });
    }, 5000);
  }, [
    currentUser.role,
    ridesWebsocket.lastJsonMessage,
    ridesWebsocket.lastMessage?.timeStamp,
    messageHistory,
    rides,
    getRideQuery,
    removeRideById,
  ]);

  return {
    count: totalCount,
    facets: apiRides.facets,
    getRides,
    isError,
    isFetching,
    rides,
    messages: (rideFetchError as FetchBaseQueryError)?.data?.messages as {
      description: string;
      statusCode: string;
    }[],
    removeRideById,
    selectAllRides,
    updateRideById,
  };
};

export default useRides;
