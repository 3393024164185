import '../../assets/stylesheets/application.scss';

import $ from 'expose-loader?exposes=$,jQuery!jquery';

import setupReactRails from '../config/setupReactRails';

window.$ = $;
window.jQuery = $;

require('jquery-ujs'); // eslint-disable-line import/first

setupReactRails();

window.Select2 = require('select2');

// Vendor
require('../../assets/javascripts/vendor/_picker');
window.Handlebars = require('../../assets/javascripts/vendor/handlebars-v4.0.5');
require('../../assets/javascripts/vendor/jquery.autosize.input');
require('../../assets/javascripts/vendor/jquery.mask.min');
require('../../assets/javascripts/vendor/jquery.multiselect');
require('../../assets/javascripts/vendor/picker.date');
require('../../assets/javascripts/vendor/picker.time');

// Config
require('../../assets/javascripts/config/ajax');
require('../../assets/javascripts/config/assign-dropdown-component');
require('../../assets/javascripts/config/component');
require('../../assets/javascripts/config/flash');
require('../../assets/javascripts/config/location-component');

// Helper
require('../../assets/javascripts/helper/bidding-helper');

// Components
require('../../assets/javascripts/components/_validator');
require('../../assets/javascripts/components/account-settings');
require('../../assets/javascripts/components/activator');
require('../../assets/javascripts/components/auto-downloader');
require('../../assets/javascripts/components/card-adder');
require('../../assets/javascripts/components/card-chooser');
require('../../assets/javascripts/components/care-coordinator-edit');
require('../../assets/javascripts/components/collapse');
require('../../assets/javascripts/components/confirm_arrival');
require('../../assets/javascripts/components/datepicker');
require('../../assets/javascripts/components/datetime');
require('../../assets/javascripts/components/email-validator');
require('../../assets/javascripts/components/facility-search');
require('../../assets/javascripts/components/generate-report');
require('../../assets/javascripts/components/google-autocomplete');
require('../../assets/javascripts/components/google-maps');
require('../../assets/javascripts/components/location-adder');
require('../../assets/javascripts/components/location-chooser');
require('../../assets/javascripts/components/location-creator');
require('../../assets/javascripts/components/location-time-display');
require('../../assets/javascripts/components/location-updater');
require('../../assets/javascripts/components/mask');
require('../../assets/javascripts/components/modal');
require('../../assets/javascripts/components/multi-select-filter');
require('../../assets/javascripts/components/overlay-ride-menu');
require('../../assets/javascripts/components/pagination');
require('../../assets/javascripts/components/patients-search-area');
require('../../assets/javascripts/components/plan-manager');
require('../../assets/javascripts/components/printer');
require('../../assets/javascripts/components/remover');
require('../../assets/javascripts/components/ride-assign');
require('../../assets/javascripts/components/ride-bid-countdown');
require('../../assets/javascripts/components/ride-decline');
require('../../assets/javascripts/components/ride-detail-bid-countdown');
require('../../assets/javascripts/components/ride-eta-bid-create');
require('../../assets/javascripts/components/ride-status-filter');
require('../../assets/javascripts/components/row-count');
require('../../assets/javascripts/components/schedule-summarizer');
require('../../assets/javascripts/components/section');
require('../../assets/javascripts/components/sort-data');
require('../../assets/javascripts/components/tab');
require('../../assets/javascripts/components/tiger-connect-credentials');
require('../../assets/javascripts/components/timepicker');
require('../../assets/javascripts/components/toggler');
require('../../assets/javascripts/components/trip-map');
require('../../assets/javascripts/components/venue-mapping');
require('../../assets/javascripts/components/will-call-filter');

// Pages
require('../../assets/javascripts/pages/admin_ride_detail');
require('../../assets/javascripts/pages/cc_reports');
require('../../assets/javascripts/pages/cc_users');
require('../../assets/javascripts/pages/dispatcher_controller');
require('../../assets/javascripts/pages/nav_dashboard');
require('../../assets/javascripts/pages/patient_show');
require('../../assets/javascripts/pages/ride_detail');
require('../../assets/javascripts/pages/trips');

// Extras

const ActionCable = require('@rails/actioncable');

window.App = {
  cable: ActionCable.createConsumer(),
};

require('../../assets/javascripts/channels/dashboard');
require('../../assets/javascripts/channels/notifications');
require('../../assets/javascripts/channels/ride_detail');
require('../../assets/javascripts/channels/ride_share_notifications');

const Turbolinks = require('turbolinks');

Turbolinks.start();

window.getCookie = (name) =>
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop();
