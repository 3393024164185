/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';

import PerPageDropdown from './PerPageDropDown/PerPageDropdown';

interface PaginationButtonProps {
  children: React.ReactNode;
  isActive?: boolean;
  onClick: () => void;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({
  onClick,
  children,
  isActive = false,
}) => (
  <button
    className={`pagination-link ${isActive ? 'is-active' : ''}`}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
);

const Pagination: React.FC<{
  page: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  total: number;
}> = ({ rowsPerPage = 10, total, setPage, page }) => {
  if (total <= 0) {
    return null; // Do not render pagination on empty rides table
  }
  // create a page list to display all the pages?
  const prevPage = page - 1;
  const nextPage = page + 1;
  const finalPage = Math.ceil(total / rowsPerPage);

  const currPageEnd = rowsPerPage * page;
  const end = currPageEnd <= total ? currPageEnd : total; // Ensure we don't go over
  const start = total > 0 ? (page - 1) * rowsPerPage + 1 : 0;

  const shouldShowPrev = page > 1;
  const shouldShowNext = end < total;

  const first = () => setPage(1);
  const prev = () => setPage(prevPage);
  const next = () => setPage(nextPage);
  const last = () => setPage(finalPage);

  return (
    <div className="pagination">
      <div className="spacer flex-column flex-sm-row">
        <div className="pagination-info">
          <PerPageDropdown rowsPerPage={rowsPerPage} />
          <div className="pagination-note">
            Showing {start} - {end} of {total}
          </div>
        </div>
        <ul className="pagination-items">
          {shouldShowPrev && (
            <>
              <li className="pagination-item -step">
                <PaginationButton onClick={first}>
                  <DoubleArrowLeftIcon />
                  First
                </PaginationButton>
              </li>
              <li className="pagination-item -step">
                <PaginationButton onClick={prev}>
                  <ChevronLeftIcon />
                  Prev
                </PaginationButton>
              </li>
              <li className="pagination-item">
                <PaginationButton onClick={prev}>{prevPage}</PaginationButton>
              </li>
            </>
          )}
          <li className="pagination-item">
            <PaginationButton onClick={() => null} isActive>
              {page}
            </PaginationButton>
          </li>
          {shouldShowNext && (
            <>
              <li className="pagination-item">
                <PaginationButton onClick={next}>{nextPage}</PaginationButton>
              </li>
              <li className="pagination-item -step">
                <PaginationButton onClick={next}>
                  Next
                  <ChevronRightIcon />
                </PaginationButton>
              </li>
              <li className="pagination-item -step">
                <PaginationButton onClick={last}>
                  Last
                  <DoubleArrowRightIcon />
                </PaginationButton>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
