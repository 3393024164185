/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo, useState } from 'react';

import Close from '@assets/images/CloseXSmall.svg';

import { CC_RIDE_STATUS_BADGES, Ride } from '@/types';

import { useTableContext } from '@/features/RidesTable/providers/TableProvider';

import BulkCancelModal from './BulkCancelModal';

const BulkCancelOption = ({ selectedRides }: { selectedRides: number[] }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { rides } = useTableContext();

  const isRideCancelable = (ride: Ride) => {
    if (ride?.statusBadge === CC_RIDE_STATUS_BADGES.claimed && ride.rideshare) {
      return false;
    }
    return (
      ride?.statusBadge === CC_RIDE_STATUS_BADGES.claimed ||
      ride?.statusBadge === CC_RIDE_STATUS_BADGES.checkingAvailability ||
      ride?.statusBadge === CC_RIDE_STATUS_BADGES.findingDriver
    );
  };
  const canCancel = useMemo(() => {
    return selectedRides.every((rideId: number) => {
      const ride = rides.find((iteratedRide) => iteratedRide.id === rideId);
      return ride ? isRideCancelable(ride) : false;
    });
  }, [selectedRides, rides]);

  return canCancel ? (
    <div className="">
      <button
        type="button"
        className="filter-label"
        onClick={() => setShowCancelModal(true)}
      >
        Cancel rides
        <Close />
      </button>
      <BulkCancelModal
        hide={() => setShowCancelModal(false)}
        show={showCancelModal}
        rideIds={selectedRides}
      />
    </div>
  ) : null;
};

export default BulkCancelOption;
