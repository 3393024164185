/* eslint-disable import/prefer-default-export */

import { roundtripBaseApiV3 } from '../../lib/rtk-query';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    createRideAttempt: build.mutation<void, number>({
      query: (rideId: number) => ({
        url: `rides/${rideId}/ride_attempts`,
        method: 'POST',
        body: {
          rideId,
        },
      }),
    }),
  }),
});

export const { useCreateRideAttemptMutation } = extendedApi;
