import React from 'react';

import TripDetails from './TripDetails';

const CancelTrip: React.FC<{
  outboundDate: string;
  outboundTime: string;
  returnDate: string;
  returnTime: string;
}> = ({ outboundDate, outboundTime, returnDate, returnTime }) => {
  return (
    <div className="d-flex">
      <TripDetails
        rideType="Outbound ride"
        date={outboundDate}
        time={outboundTime}
      />
      <TripDetails rideType="Return ride" date={returnDate} time={returnTime} />
    </div>
  );
};

export default CancelTrip;
