import { useEffect } from 'react';

import type { GlobalSSRData } from '@/types';

import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { ROLES } from '@/types';

import { pushFlashMessage } from '../../store/flashMessagesSlice';
import FlashMessages from '../FlashMessages';

const Container = () => {
  // eslint-disable-next-line no-underscore-dangle
  const { flash, currentUser } = (window as GlobalSSRData)._unsafeSSRData
    .global;
  const dispatch = useDispatch();

  useEffect(() => {
    if (flash?.length > 0) {
      flash.forEach(([type, text]) => {
        // Rails passes in `['alert', nil]` when flash list is empty and `[true, 'timeout']` when sessions expire.
        if (
          !type ||
          !text ||
          typeof type !== 'string' ||
          typeof text !== 'string'
        ) {
          return;
        }

        dispatch(
          pushFlashMessage({
            text,
            type,
          }),
        );
      });
    }
  }, [flash]);

  // Todo: Make this a user preference setting
  const containerClassName =
    currentUser.role === ROLES.admin
      ? 'wrapper mt-6 pb-10 px-5 px-xl-8'
      : 'wrapper mt-6 pb-10 -wide';

  return (
    <div
      className={containerClassName}
      id="main-community-container"
      role="main"
    >
      <FlashMessages />

      <Outlet />
    </div>
  );
};

export default Container;
