import React from 'react';

const TripDetails: React.FC<{
  date: string;
  rideType: string;
  time: string;
}> = ({ date, rideType, time }) => (
  <div className="flex-1">
    <span className="ride-type">{rideType}</span> <br />
    <span className="ride-time-date">{date}</span> <br />
    <span className="ride-time-date">{time}</span>
  </div>
);

export default TripDetails;
