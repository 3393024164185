/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= ==============================================================================
// _validator.coffee
//= ==============================================================================
import dayjs from '@/lib/dayjs'


// Front end validation for fields. Targets a button to disable / enable based
// on the validity of fields inside this component.
new Component('validator', ['button'], ((c) => {
  let $outboundTripWillCallEl; let
    $returnTripWillCallEl;
  if (document.getElementById('outbound_trip_time_will_call')) {
    $outboundTripWillCallEl = document.getElementById('outbound_trip_time_will_call');
    $returnTripWillCallEl = document.getElementById('return_trip_time_will_call');
  } else if (document.getElementById('outbound_trip_will_call')) {
    $outboundTripWillCallEl = document.getElementById('outbound_trip_will_call');
    $returnTripWillCallEl = document.getElementById('return_trip_will_call');
  } else if (document.getElementById('outbound_trip_will_call_responsive')) {
    $outboundTripWillCallEl = document.getElementById('outbound_trip_will_call_responsive');
    $returnTripWillCallEl = document.getElementById('return_trip_will_call_responsive');
  }

  const dateFormatDMY = 'DD/MM/YYYY hh:mm A';
  const dateTimeFormatMDY = 'MM/DD/YYYY hh:mm A';
  const dateFormatMDY = "MM/DD/YYYY";

  const hideOutboundTripTimeButton = function () {
    if (document.getElementById('outbound_trip_time_will_call') && document.getElementById('outbound_trip_time_will_call').checked) {
      return $('#outbound_trip_time').hide();
    }
  };

  const hideReturnTripTimeButton = function () {
    if (document.getElementById('return_trip_time_will_call') && document.getElementById('return_trip_time_will_call').checked) {
      return $('#return_trip_time').hide();
    }
  };


  const getCurrentTimeStamp = function () {
    let timeZone = null;
    const tzValue = $('.pickup-location .location_timezone').val();
    if (tzValue) {
      timeZone = dayjs().tz(tzValue);
    }
    return timeZone || dayjs();
  };

  if ($(c.this).find(c.button).hasClass('disable-if-any-error')) {
    $(c.this).find(c.button).attr('disabled', true);
  }

  // Update return trip time
  const updateReturnTripTime = function (outboundTimestamp, returnTimestamp) {
    const timeStamp = outboundTimestamp < returnTimestamp ? returnTimestamp : outboundTimestamp.clone().add(1, 'hour');

    // Adding milliseconds to UNIX timstamp for incrementing 1 hr in future
    const outbound_date = timeStamp.format('MM-DD-YYYY H:mm:a');

    const picker_data = getTimeFormat(outbound_date);

    // Update return calendar component
    $('#return-date-calendar').val(picker_data.date);
    $('#return-date-calendar').trigger('set-date', [picker_data.date]);

    // Update return time select boxes
    $('#return_trip_time').val(`${picker_data.time} ${picker_data.meridian}`);
    return $('#return_trip_time').trigger('set-time', [`${picker_data.time} ${picker_data.meridian}`]);
  };

  // the date selected from the datepicker is in MM/DD/YYYY,
  // we convert that to DD/MM/YYYY for ruby here for generating
  // the valid date from moment we are using the date format
  const getTimeAccordingToLocationTimezone = function (date, format) {
    const locationTimezone = $('.pickup-location .location_timezone').val();
    if (locationTimezone) {
      return dayjs(date, format).tz(locationTimezone);
    }
    return dayjs(date, format);
  };


  // Generates a Unix timestamp given a date and time
  const getDateTime = function (datetime, format) {
    const string = document.getElementById(datetime).value;
    return getTimeAccordingToLocationTimezone(string, format);
  };

  const getDateTimeDetermineFormat = function (datetime) {
    const string = document.getElementById(datetime).value;
    let format = dateFormatMDY;
    const date = dayjs(string, format);
    if (!date.isValid()) {
      format = dateTimeFormatMDY;
    }
    return getTimeAccordingToLocationTimezone(string, format);
  };



  const padding = function (item, c, s) {
    const len_str = String(item).length;
    if (s < len_str) {
      return item;
    }
    return Array((s - len_str) + 1).join(c) + item;
  };

  const compareDates = function (previous, current, willCall, compareOnlyDay) {
    compareOnlyDay ??= false;
    if ((willCall && willCall.checked) || compareOnlyDay) {
      return current.isSameOrAfter(previous, 'day');
    }
    return previous <= current;
  };

  var getTimeFormat = function (string) {
    const data = string.match(/^(\d{2})-(\d{2})-(\d{4}) (\d{1,2}):(\d{2}):([a-z]{2})/);

    return {
      date: `${data[1]}/${data[2]}/${data[3]}`,
      meridian: data[6].toUpperCase(),
      time: `${data[4] === '00' ? '12' : (parseInt(data[4]) >= 13) ? padding(parseInt(data[4] - 12), '0', 2) : data[4]}:${data[5]}`,
    };
  };

  // Ensure "button" component target is clicked on "enter" keypress
  // Override "enter" keypress with "no-enter-submission" class name
  $(c.this).on('keyup keypress', (e) => {
    const keyCode = e.keyCode || e.which;

    for (const className of Array.from(c.this.classList)) {
      if (className === 'no-enter-submission') { return; }
    }

    if (keyCode === 13) {
      e.preventDefault();
      $(c.this).find(c.button).click();
      return false;
    }
  });

  // Look specifically for form fields with errors and disable / enable button
  //
  // @return {void}
  const updateButtonState = function () {
    if (($(c.this).find('.field.-error:visible').length > 0)
      || ($(c.this).find('.error-field.-error:visible').length > 0)) {
      $(c.this).find(c.button).attr('disabled', true);
      return $(c.this).find('.form-invalid-message').show();
    }
    let hasError = false;
    if ($(c.this).find(c.button).hasClass('disable-if-any-error')) {
      const inputValues = [];
      $(c.this).find(':not(:disabled) [required]').each(function () {
        if ($(this).val()) {
          return inputValues.push($(this).val());
        }
      });
      hasError = $(c.this).find(':not(:disabled) [required]').length !== inputValues.length;
    }
    $(c.this).find(c.button).attr('disabled', hasError);
    if (hasError) {
      return $(c.this).find('.form-invalid-message').show();
    }
    return $(c.this).find('.form-invalid-message').hide();
  };

  const showBudgetErrorModal = function (errorMsg) {
    $('#budgetErrorMessage').html(errorMsg);
    const ridesBudgetLimitModal = $('#rides-budget-limit-modal');
    ridesBudgetLimitModal.addClass('is-active');
    ridesBudgetLimitModal.trigger('modal:opened');
    return $(c.this).find(c.button).attr('disabled', true);
  };

  // Validate budget and Maximum allowance per trip
  const validateBudgetLimits = function () {
    const action = $('#patient_needs_form').attr('action').replace('patient_needs', 'estimated_fare');
    return $.ajax(action, {
      data: $('#patient_needs_form').serialize(),
      error(jqXHR, textStatus, errorThrown) {
        return showBudgetErrorModal(jqXHR.responseJSON.message);
      },
      success(data, textStatus, jqXHR) {
        return $('#patient_needs_form').submit();
      },
      type: 'POST',
    });
  };

  // Mark form as valid if no errors are visible
  //
  // @return {void}
  const setValidityState = function () {
    if ($(c.this).find('.field.-error:visible').length === 0) {
      $(c.this).addClass('is-valid');
      return $(c.this).removeClass('is-invalid');
    }
    $(c.this).addClass('is-invalid');
    return $(c.this).removeClass('is-valid');
  };

  const showRidesExceededModal = function (response) {
    $('#rides-limit-message').html(response.message);
    $('#rides-limit-heading').html(response.heading);
    const ridesLimitModal = $('#rides-limit-modal');
    ridesLimitModal.addClass('is-active');
    ridesLimitModal.trigger('modal:opened');
    return $(c.this).find(c.button).attr('disabled', true);
  };

  // Add error on input phone number field if the validation of number from
  // twilio is in progress.
  const validatePhoneNumberField = () => $('[data-validate-phone]').each(function () {
    if ($(this).attr('data-validating-number') === 'true') {
      // Remove warning from the field
      $(this).closest('.field').removeClass('-warning');
      $(this).siblings('.warning-message').addClass('-hidden');
      $(this).siblings('.warning-message').find('.small').html('');

      // Add `Still validating` error on the field
      $(this).closest('.field').addClass('-error');
      $(this).siblings('.error-message').find('.small').html('Still validating number.');
      return $(this).siblings('.error-message').removeClass('-hidden');
    }
  });

  const validateRidesLimit = function (e, ridesLeft) {
    const action = $('#setup_trip_form').attr('action').replace('setup_trip', 'validate_rides');
    return $.ajax(action, {
      data: $('#setup_trip_form').serialize(),
      error(jqXHR, textStatus, errorThrown) {
        return showRidesExceededModal(jqXHR.responseJSON);
      },
      success(data, textStatus, jqXHR) {
        return $('#setup_trip_form').submit();
      },
      type: 'POST',
    });
  };

  const validatePcsForm = function () {
    // Check only if PCS form exists on page
    if ($('#pcs-form').length > 0) {
      // check if stretcher is selected
      if ($('#pcs-form').parents('.form-hidden').first().hasClass('is-active')) {
        // check that PCS form is not disabled
        if ($('#pcs-form').find('.disabled-wrapper').length === 0) {
          if ($('#pcs-form').attr('pcs_uploaded') === 'false') {
            return $('#pcs-form').addClass('-error');
          }
          return $('#pcs-form').removeClass('-error');
        }
      }
    }
  };

  const validateCancelRide = function () {
    if ($('#cancelation_reason').length > 0) {
      if ($('#cancelation_reason').val() === 'other') {
        return $('#additional_cancelation_info').attr('required', true);
      }
      $('#additional_cancelation_info').removeAttr('required');
      return $('#additional_cancelation_info').parents().removeClass('-error');
    }
  };

  // Validate time in the ride_time_edit modal. Return true if there is
  // error.
  //
  // @param {number} rideId
  // @param {object} $field - jQuery object
  // @return {boolean}
  const validateRideTimeEdit = function (rideId, $field) {
    const timeType = $field.find('select').val();
    if (timeType === 'leave_now') { return false; }
    let hasError = false;
    const outboundTripWillCallEl = document.getElementById(`outbound_trip_will_call_${rideId}`);
    const returnTripWillCallEl = document.getElementById(`return_trip_will_call_${rideId}`);
    const data = document.getElementById(`edit-ride-time-attributes-${rideId}`).dataset;
    const rideDateTime = getDateTime(`${data.rideType}-datetime-${rideId}`, dateFormatDMY);
    const rideDate = getDateTimeDetermineFormat(`${data.rideType}-date-calendar-${rideId}`);
    let willCallElement = null;
    const now = getCurrentTimeStamp();

    if (data.tripType === 'round_trip') {
      switch (data.rideType) {
        case 'outbound':
          if ((outboundTripWillCallEl && outboundTripWillCallEl.checked)
            || (data.returnRideWillCall === 'true')) {
            if (data.returnRideDate) {
              willCallElement = outboundTripWillCallEl;
              const returnRideDate = getTimeAccordingToLocationTimezone(data.returnRideDate, dateTimeFormatMDY);
              hasError = !compareDates(rideDate, returnRideDate, outboundTripWillCallEl, true);
            }
          } else if (data.maxOutboundRideTime) {
            const maxOutboundRideTime = getTimeAccordingToLocationTimezone(data.maxOutboundRideTime, dateFormatDMY);
            hasError = !compareDates(rideDateTime, maxOutboundRideTime, false);
          }
          break;
        case 'return':
          if ((returnTripWillCallEl && returnTripWillCallEl.checked)
            || (data.outboundRideWillCall === 'true')) {
            if (data.outboundRideDate) {
              willCallElement = returnTripWillCallEl;
              const outboundRideDate = getTimeAccordingToLocationTimezone(data.outboundRideDate, dateFormatMDY);
              hasError = !compareDates(outboundRideDate, rideDate, returnTripWillCallEl, true);
            }
          } else if (data.minReturnRideTime) {
            const minReturnRideTime = getTimeAccordingToLocationTimezone(data.minReturnRideTime, dateFormatDMY);
            hasError = !compareDates(minReturnRideTime, rideDateTime, false);
          }
          break;
      }
    }

    if (hasError) {
      let error_message;
      switch (data.rideType) {
        case 'outbound':
          error_message = 'Please specify an outbound time before the return time.';
          break;
        default:
          error_message = 'Please specify a return time after the outbound time.';
      }
      $('.field-error-note > small', $field).html(error_message);
    } else {
      hasError = !compareDates(now, rideDateTime, willCallElement);
      if (hasError) {
        $('.field-error-note > small', $field).html('Please specify a time in the future');
      }
    }

    return hasError;
  };

  // to validate time and data
  const validateTime = function (ride1Eta, ride2Eta) {
    const hasError = !(ride1Eta.getTime() <= ride2Eta.getTime());
    return hasError;
  };

  // to display error messages
  const displayErrorMessage = function (rideType) {
    let error_message;
    switch (rideType) {
      case 'outbound':
        error_message = 'Please specify an outbound time before the return time.';
        break;
      default:
        error_message = 'Please specify a return time after the outbound time.';
    }
    return error_message;
  };

  // Submit ETA modal validation
  const validateSubmitETA = function ($field) {
    let ride2Data;
    let hasError = false;

    const outboundTime = document.getElementById('assign_outbound_trip_time');
    const returnTime = document.getElementById('assign_return_trip_time');
    const outboundDate = document.getElementById('assign-outbound-date-calendar');
    const returnDate = document.getElementById('assign-return-date-calendar');

    const now = getCurrentTimeStamp();

    let ride1Data = document.getElementById('set-ride-1-attributes');
    if (ride1Data !== null) {
      ride1Data = ride1Data.dataset;
    }

    // if the trip is oneway or the TC wants only one leg
    const element = document.getElementById('radio-btn-other-ride');
    if ((element !== null) && element.checked) {
      ride2Data = document.getElementById('set-ride-2-attributes');
      if (ride2Data !== null) {
        ride2Data = ride2Data.dataset;
      }
    } else {
      return hasError;
    }

    // if the trip is round_trip
    if (ride1Data.tripType === 'round_trip') {
      // for ride1 date and time field validation
      let error_message;
      const outboundETA = new Date(`${outboundDate.value} ${outboundTime.value}`);
      const returnETA = new Date(`${returnDate.value} ${returnTime.value}`);
      hasError = validateTime(outboundETA, returnETA);

      if (hasError && (ride1Data.rideType !== 'return')) {
        error_message = displayErrorMessage(ride1Data.rideType);
        $('.field-error-note > small', $field).html(error_message);
        return hasError;
      }

      // for ride2 date and time field validation
      if (ride2Data !== null) {
        hasError = validateTime(outboundETA, returnETA);
        error_message = displayErrorMessage(ride2Data.rideType);
        $('.field-error-note > small', $field).html(error_message);
        return hasError;
      }
    }

    return hasError;
  };

  // Allow non-required fields to re-enable button on edit
  $(c.this).on('blur change', '.field input:not([required])', function () {
    const $field = $(this).parents('.field').first();
    return $field.removeClass('-error');
  });

  // Allow non-required dropdowns to re-enable button on edit
  $(c.this).on('blur change', '.field select:not([required]):not(.-avoid-validity-reset)', function () {
    const $field = $(this).parents('.field').first();
    $field.removeClass('-error');

    // Validate time on ride-time-edit modal after time-type is changed
    if ($(this).hasClass('-ride-time-edit')) {
      const rideId = $field.find('#ride-id').val();
      const hasError = validateRideTimeEdit(rideId, $field);
      if (hasError) { return $field.addClass('-error'); }
    }
  });

  // Attach validations on inputs within groups
  $(c.this).on('blur change', '.field[required] input:not(:disabled)', function () {
    const $field = $(this).parents('.field').first();
    let hasError = true;

    // Radios/checkboxes - check each other related radio and look for any selected
    if (($(this).attr('type') === 'radio') || ($(this).attr('type') === 'checkbox')) {
      hasError = true;
      $(`[name='${$(this).attr('name')}']`).each(function () {
        if ($(this).is(':checked')) { return hasError = false; }
      });
    }

    // Mark error or not
    if (hasError) { return $field.addClass('-error'); } return $field.removeClass('-error');
  });

  // Attach validations on not required fields having maxlen attribute defined
  $(c.this).on('input change blur', '.field .maxlength', function (e) {
    const len = $(this).val().length;
    if (len > $(this).attr('maxlen')) {
      $(this).parents('.field').first().addClass('-error');
    } else {
      $(this).parents('.field').first().removeClass('-error');
    }
    return updateButtonState();
  });

  // Attach validations on individual inputs with the `required` attribute
  $(c.this).on('input change blur', '[required]:not(:disabled)', function (event, calendarType) {
    if ($(this).parents('.field').length > 0) {
      let len; let min; let minTimestamp; let needle; let needle1; let needle2; let needle3; let needle4; let now; let outboundTimestamp; let returnTimestamp; let val; let
        valTimestamp;
      const $field = $(this).parents('.field').first();
      let hasError = true;

      // Minlength
      if ($(this).attr('minlength') !== undefined) {
        len = $(this).val().length;
        hasError = true;
        if (len >= $(this).attr('minlength')) { hasError = false; }
      }

      // Maxlength
      if ($(this).attr('maxlength') !== undefined) {
        len = $(this).val().length;
        hasError = true;
        hasError = !len || (len > $(this).attr('maxlength'));
      }

      // No lengths
      if (($(this).attr('minlength') === undefined) && ($(this).attr('maxlength') === undefined)) {
        hasError = true;
        if (($(this).val() !== '') && ($(this).val() !== null)) { hasError = false; }
      }

      if (
        ($(this).attr('type') === 'phone')
        && (event.type === 'input')
        && ($(document.activeElement).val() === '')
      ) {
        hasError = false;
      }

      // Password
      if ($(this).attr('password') !== undefined) {
        hasError = true;
        const validPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test($(this).val());
        if (validPassword) { hasError = false; }
      }

      // Email
      if (($(this).attr('type') === 'email') && (event.type === 'focusout')) {
        const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
        hasError = !emailRegex.test($(this).val());
      }

      // Image uploads
      if ($(this).attr('type') === 'file') {
        hasError = true;
        if ($(this).parents('.upload').find('.upload-image').attr('style') !== '') { hasError = false; }
      }

      // Radios - check each other related radio and look for any selected
      if ($(this).attr('type') === 'radio') {
        hasError = true;
        $(`[name='${$(this).attr('name')}']`).each(function () {
          if ($(this).is(':checked')) { return hasError = false; }
        });
      }

      // Zipcodes
      if (($(this).attr('placeholder') === 'Zipcode') || ($(this).attr('placeholder') === 'Zip')) {
        hasError = true;
        if ($(this).val().match(/^\d[\d-]*\d$/)) {
          hasError = false;
        }
      }

      // Phone numbers
      if ($(this).attr('data-mask-type') === 'phone') {
        if (event.type === 'focusout') {
          hasError = $(this).val().length !== 12;
        } else if ((event.type === 'input') && ($(document.activeElement).val() === '')) {
          hasError = false;
        }
      }

      // Simple date fields
      if (($(this).attr('data-mask-type') === 'date') && (event.type === 'focusout')) {
        const date = $(this).val();
        hasError = true;

        if (date.length === 10) {
          const m = dayjs(date, 'MM/DD/YYYY');
          hasError = !m.isValid();
        }
      }

      // Date field (MM/DD/YYYY)
      //
      // Has some special functionality: will copy over input value to a field
      // with name defined by a data-proxy-for attribute in DD/MM/YYYY format
      // once it is validated on front end.
      if ($(this).attr('data-proxy-for')) {
        // TODO: Revise this hacky logic
        if ((event.type === 'focusout') && ($('#return-ride-edit-time').length !== 0)) { return; }

        hasError = true;
        const parsed = $(this).val().split('/');
        if ((parsed.length === 3) && ($(this).val().length === 10)
          && (parseInt(parsed[1]) <= 31) && (parseInt(parsed[0]) <= 12)) {
          hasError = false;
          const ddmmyyyy = `${parsed[1]}/${parsed[0]}/${parsed[2]}`;
          const proxyFor = $(this).attr('data-proxy-for');
          $(`[name='${proxyFor}']`).val(ddmmyyyy).trigger('change');
        }
      }

      // Unique values
      if (typeof $(this).attr('data-unique') !== 'undefined') {
        hasError = true;
        const _this = this;
        $(`${c.selector} [data-unique='${$(this).attr('data-unique')}']`).each(function () {
          if ($(this).val() === $(_this).val()) {
            return hasError = false;
          }
        });
      }

      let ids = ['assign-outbound-datetime', 'assign-outbound-date-calendar', 'assign_outbound_trip_time'];
      if ((needle = $(this).attr('id'), Array.from(ids).includes(needle))) {
        min = $('#assign-outbound-datetime').attr('min-value');
        val = $('#assign-outbound-datetime').val();
        now = getCurrentTimeStamp();
        if (min && (min !== '') && val && (val !== '')) {
          valTimestamp = getTimeAccordingToLocationTimezone(val, dateFormatDMY);
          minTimestamp = getTimeAccordingToLocationTimezone(min, dateFormatDMY);
          hasError = !compareDates(minTimestamp, valTimestamp, false);
          if (!hasError) {
            hasError = !compareDates(now, valTimestamp, false);
          }
        }
      }

      ids = ['assign-return-datetime', 'assign-return-date-calendar', 'assign_return_trip_time'];
      if ((needle1 = $(this).attr('id'), Array.from(ids).includes(needle1))) {
        min = $('#assign-return-datetime').attr('min-value');
        val = $('#assign-return-datetime').val();
        now = getCurrentTimeStamp();
        if (min && (min !== '') && val && (val !== '')) {
          valTimestamp = getTimeAccordingToLocationTimezone(val, dateFormatDMY);
          minTimestamp = getTimeAccordingToLocationTimezone(min, dateFormatDMY);
          hasError = !compareDates(minTimestamp, valTimestamp, false);
          if (!hasError) {
            hasError = !compareDates(now, valTimestamp, false);
          }
        }
      }

      // Ensure outbound time is not in the past
      ids = ['outbound-date-calendar', 'outbound_trip_time', 'outbound_meridian'];
      if ((needle2 = $(this).attr('id'), Array.from(ids).includes(needle2))) {
        hasError = true;

        if (document.getElementById('outbound-datetime')) { outboundTimestamp = getDateTime('outbound-datetime', dateFormatDMY); }
        if (document.getElementById('return-datetime')) { returnTimestamp = getDateTime('return-datetime', dateFormatDMY); }
        // Checking if time is valid and update return date time if outbound calendar was updated
        if ((event.type === 'change') && (calendarType !== 'returning')) {
          updateReturnTripTime(outboundTimestamp, returnTimestamp);
        }

        now = getCurrentTimeStamp();

        // Outbound trip timestamp can't be less than now's timestamp
        hasError = !compareDates(now, outboundTimestamp, $outboundTripWillCallEl);
      }

      // Ensure return time is not in the past
      ids = ['return-date-calendar', 'return_trip_time', 'return_meridian'];
      if ((needle3 = $(this).attr('id'), Array.from(ids).includes(needle3)) && $('#trip_trip_type_round_trip').is(':checked')) {
        hasError = true;
        returnTimestamp = getDateTime('return-datetime', dateFormatDMY);
        now = getCurrentTimeStamp();

        // Return trip's timestamp can't be less than now's timestamp, ignore if return trip time is "will_call"
        hasError = !compareDates(now, returnTimestamp, $returnTripWillCallEl);
        if (hasError) {
          $('.field-error-note > small', $field).html('Please specify a time in the future');
        }
      }

      // Special case: return trip time
      ids = ['outbound-date-calendar', 'return-date-calendar', 'return_trip_time', 'return_meridian'];
      if ((needle4 = $(this).attr('id'), Array.from(ids).includes(needle4)) && $('[data-activator-target="return-trip-time"]').hasClass('is-active')) {
        let error_message;
        hasError = true;
        const outboundDayTimestamp = getDateTime('outbound-date-calendar', dateTimeFormatMDY);
        const returnDayTimestamp = getDateTime('return-date-calendar', dateTimeFormatMDY);
        outboundTimestamp = getDateTime('outbound-datetime', dateFormatDMY);
        returnTimestamp = getDateTime('return-datetime', dateFormatDMY);
        now = getCurrentTimeStamp();

        let willCallElement = null;
        if ($returnTripWillCallEl && $returnTripWillCallEl.checked) {
          willCallElement = $returnTripWillCallEl;
        } else if ($outboundTripWillCallEl && $outboundTripWillCallEl.checked) {
          willCallElement = $outboundTripWillCallEl;
        }

        if (willCallElement) {
          hasError = !compareDates(outboundDayTimestamp, returnDayTimestamp, willCallElement);
        } else {
          hasError = !compareDates(outboundTimestamp, returnTimestamp);
        }

        // Valid if outbound trip is will call and return trip is same day or
        // greater
        hasError = hasError || !compareDates(now, returnDayTimestamp, $returnTripWillCallEl, true);

        if (hasError) {
          switch ($(this).attr('id')) {
            case 'outbound-date-calendar':
              error_message = 'Please specify an outbound date before the return date.';
              break;
            default:
              error_message = 'Please specify a return time after the outbound time.';
          }
        } else {
          error_message = 'Please specify a time in the future';
        }
        $('.field-error-note > small', $field).html(error_message);
      }

      const activeModalRideIdElement = $('.modal-overlay.is-active #ride-id');

      if (activeModalRideIdElement) {
        let needle5; let
          needle6;
        const rideId = activeModalRideIdElement.val();
        ids = [`outbound-date-calendar-${rideId}`, `return-date-calendar-${rideId}`,
        `outbound_trip_time_${rideId}`, `return_trip_time_${rideId}`];
        const idsToValidateSetETAModel = ['assign-outbound-date-calendar', 'assign_outbound_trip_time',
          'assign-return-date-calendar', 'assign_return_trip_time'];
        if ((needle5 = $(this).attr('id'), Array.from(ids).includes(needle5))) {
          hasError = validateRideTimeEdit(rideId, $field);
        }

        if ((needle6 = $(this).attr('id'), Array.from(idsToValidateSetETAModel).includes(needle6))) {
          hasError = validateSubmitETA($field);
        }
      }

      if ($field.hasClass('full-address')) {
        if ((event.type === 'focusout') && ($('.modal-overlay.is-active .street-address').val().length === 0)) {
          hasError = true;
        } else if ((event.type === 'input') && ($(document.activeElement).val() === '')) {
          hasError = false;
        }
      }

      // Return if skipRemoveError attr is present
      if (!hasError && ($(this).attr('skipRemoveError') === 'true')) { return; }

      // Mark error or not
      if (hasError) {
        $field.addClass('-error');
      } else {
        $field.removeClass('-error');
      }

      if ($(this).attr('update_button_state') !== undefined) {
        return updateButtonState();
      }
    }
  });

  $(c.this).on('change', '#cancelation_reason', () => validateCancelRide());

  $(c.this).on('blur change', '#trip_trip_type_round_trip', () => {
    const outboundTimestamp = getDateTime('outbound-datetime', dateFormatDMY);
    const returnTimestamp = getDateTime('return-datetime', dateFormatDMY);

    updateReturnTripTime(outboundTimestamp, returnTimestamp);

    return $('#return_trip_time').trigger('change');
  });

  $(c.this).on('blur change', '#outbound_trip_will_call_responsive, #outbound_trip_will_call', () => $('#outbound-date-calendar').trigger('change'));

  $(c.this).on('blur change', '#return_trip_will_call, #return_trip_will_call_responsive', () => $('#return-date-calendar').trigger('change'));

  $(c.this).on('blur change', '#outbound_trip_time_will_call', () => {
    if ($outboundTripWillCallEl && $outboundTripWillCallEl.checked) {
      $('#outbound_trip_time').hide();
    } else {
      $('#outbound_trip_time').show();
    }
    return $('#outbound_trip_time').trigger('change');
  });

  $(c.this).on('blur change', '#return_trip_time_will_call', () => {
    if ($returnTripWillCallEl && $returnTripWillCallEl.checked) {
      $('#return_trip_time').hide();
    } else {
      $('#return_trip_time').show();
    }
    return $('#return_trip_time').trigger('change');
  });

  $(document).on('location-updated', (event, data) => {
    $('#return_trip_time').trigger('change');
    return $('#outbound_trip_time').trigger('change');
  });

  // Special case: outbound trip time triggers return trip time validation
  $(c.this).on('blur change', '#outbound_trip_time, #outbound_meridian', () => $('#return_trip_time').trigger('change'));

  // trigger validation when datepickers change
  $(document).off('datepicker-closed').on('datepicker-closed', (event, element) => {
    if (element.id) {
      switch (element.id) {
        case 'outbound-date-calendar': return $('#return_trip_time').trigger('change');
        case 'return-date-calendar': return $('#outbound_trip_time').trigger('change', ['returning']);
      }
    }
  });

  // Fire all validations upon clicking button, and disable / enable depending
  // on whether there are errors.
  $(c.button).on('click', (e) => {
    $(c.this).find(':not(:disabled) [required]').blur();
    $(':not(:disabled) [required] input').blur();
    $(c.this).find('.field .maxlength').blur();
    validatePcsForm();
    validatePhoneNumberField();
    updateButtonState();
    setValidityState();

    // Prevent other events from firing if the button is disabled
    if ($(c.button).is(':disabled')) {
      e.stopImmediatePropagation();
      return e.preventDefault();
    } if ($(c.button).data('validatorTarget') === 'continue') {
      if ($('#setup_trip_form').length > 0) {
        validateRidesLimit();
        e.stopImmediatePropagation();
        e.preventDefault();
      }
      if ($('#patient_needs_form').length > 0) {
        validateBudgetLimits();
        e.stopImmediatePropagation();
        return e.preventDefault();
      }
    }
  });

  hideOutboundTripTimeButton();
  hideReturnTripTimeButton();

  // Listen for additional, manually triggered change events to update button
  // state.
  return $(document).on('change', updateButtonState);
}));
