import type { CellComponent } from '@/features/RidesTable/types';
import TimeCell from './TimeCell';

const PickupTimeCell: CellComponent = ({ ride, href }) => {
  const etaStatuses = ['claimed', 'to_pickup', 'to_dropoff', 'at_pickup', 'at_dropoff']
  const showEta = ride.rideshare && etaStatuses.includes(ride.currentStatus);
  return (
    <TimeCell
      time={
        ride.expectedRideStartTime ? ride.expectedRideStartTime : ride.startTime
      }
      timeZone={ride.timezone}
      href={typeof href === 'function' ? href(ride) : href}
      willCall={ride.willCall}
      label="Pickup time"
      eta={showEta}
    />
  );
};

export default PickupTimeCell;
