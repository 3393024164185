import * as React from 'react';

import { useSelector } from 'react-redux';

import { selectSelectedRides } from '@/features/RidesTable/store/selectedRidesSlice';

import BulkCancelOption from './BulkCancelOption';
import BulkCompleteOption from './BulkCompleteOption';
import BulkDeclineOption from './BulkDeclineOption';
import BulkReleaseOption from './BulkReleaseOption';

const Root: React.FC<{
  children: React.ReactNode;
  visible?: boolean;
}> = ({ children, visible = true }) => {
  const selectedRidesQuantity = useSelector(selectSelectedRides).length;

  return (
    <div
      className={`${visible ? `d-flex` : `d-none`}
           flex-row flex-justify-start mb-4`}
      style={{ overflowX: 'auto' }}
    >
      <div className="bulk-action-header-label">
        {`${selectedRidesQuantity} ride${selectedRidesQuantity === 1 ? '' : 's'} selected`}
      </div>
      {children}
    </div>
  );
};

const BulkActionHeaderBar = {
  Root,
  BulkDeclineOption,
  BulkReleaseOption,
  BulkCompleteOption,
  BulkCancelOption,
};

export default BulkActionHeaderBar;
