import { useMemo } from 'react';

import type { CellComponent } from '@/features/RidesTable/types';
import type { CcRideStatusBadges, Ride, StreamableRide } from '@/types';

import { CheckIcon } from '@radix-ui/react-icons';

import Cell from '@/features/RidesTable/components/Table/Cell';
import RideStatusBadge from '@/features/RideStatusBadge';
import {
  CC_ICONS,
  CC_RIDE_STATUS_BADGE_TEXT,
  CC_STYLES,
} from '@/features/RideStatusBadge/types';
import { AUTH_DISPLAY_NAMES } from '@/types';

import * as tooltips from './tooltips';

/* eslint-disable camelcase */
export const CC_TOOLTIPS = {
  canceled: null,
  completed: null,
  brokered: null,
  on_hold: null,
  will_call: null,
  incomplete: null,
  requested: null,
  finding_driver: null,
  claimed: tooltips.TransportationCompanyAwarded,
  finding_providers: null,
  checking_availability: tooltips.ResponsesReceived,
  at_pickup: tooltips.TransportationCompanyAwarded,
  to_pickup: tooltips.TransportationCompanyAwarded,
  at_destination: tooltips.TransportationCompanyAwarded,
  to_destination: tooltips.TransportationCompanyAwarded,
} as const;
/* eslint-enable camelcase */

const getTooltip = (ride: Ride) => {
  if (
    ride.rideshare &&
    ['at_pickup', 'to_pickup', 'to_destination'].includes(
      ride.statusBadge as CcRideStatusBadges,
    )
  ) {
    return null;
  }

  return CC_TOOLTIPS[ride.statusBadge as CcRideStatusBadges];
};

const showConfirm = (ride: StreamableRide) => {
  const confirmedPickup = Boolean(ride.pickedUpAt);
  const confirmedDropoff = Boolean(ride.droppedOffAt);

  const isNotAtDestinationOrCompleted =
    ride.statusBadge !== 'at_destination' && ride.statusBadge !== 'completed';
  const isAtDestinationOrCompleted =
    ride.statusBadge === 'at_destination' || ride.statusBadge === 'completed';

  return (
    (confirmedPickup && isNotAtDestinationOrCompleted) ||
    (confirmedDropoff && isAtDestinationOrCompleted)
  );
};

const populateSubIcon = (ride: StreamableRide) => {
  let SubIcon;

  if (showConfirm(ride)) {
    SubIcon = CheckIcon;
  }

  return SubIcon;
};

const populateSubText = (ride: StreamableRide) => {
  let subtext: string | null = ride.authStatus
    ? AUTH_DISPLAY_NAMES[ride.authStatus]
    : null;

  if (showConfirm(ride)) {
    subtext = 'Confirmed';
  }
  return subtext;
};

const RideStatusBadgeCell: CellComponent = ({ ride, href }) => {
  const { name } = useMemo(
    () => ({
      name: ride.riderFullName.split(' ').join('-').toLowerCase(),
    }),
    [ride],
  );

  const style = CC_STYLES[ride.statusBadge as CcRideStatusBadges];
  const Icon = CC_ICONS[ride.statusBadge as CcRideStatusBadges];
  const SubIcon = populateSubIcon(ride);
  const subtext = populateSubText(ride);
  const ToolTip = getTooltip(ride);

  return (
    <Cell
      label="Status"
      tdClass="time-remaining -overflow"
      href={typeof href === 'function' ? href(ride) : href}
      anchorClass="py-8"
    >
      <RideStatusBadge.Root color={style}>
        <div className="d-flex">
          <RideStatusBadge.Content id={`${name}-bid-status-message`}>
            {Icon && <Icon />}
            {CC_RIDE_STATUS_BADGE_TEXT[ride.statusBadge as CcRideStatusBadges]}
          </RideStatusBadge.Content>

          {ToolTip && <ToolTip ride={ride} />}
        </div>

        {subtext && (
          <RideStatusBadge.Subtext>
            {SubIcon ? <SubIcon /> : null} {subtext}
          </RideStatusBadge.Subtext>
        )}
      </RideStatusBadge.Root>
    </Cell>
  );
};

export default RideStatusBadgeCell;
