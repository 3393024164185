import React from 'react';

import { formatDateMMMMDoYYYY } from '@/utils/dateTime';

const RideTimes: React.FC<{
  date: string;
  timeZone: string;
}> = ({ date, timeZone }) => {
  return (
    <span className="last-ride-date">
      {formatDateMMMMDoYYYY(date, timeZone)}
    </span>
  );
};

export default RideTimes;
