import { useState } from 'react';
import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useSubmitRideResponseMutation } from '@/api';
import { pushFlashMessage } from '@/store/flashMessagesSlice';

import './main.scss';

import DateField from '@root/components/DateField';
import TimeField from '@root/components/TimeField';

import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { pushToastNotification } from '@/store/toastNotificationsSlice';

import Modal from './Modal';

const SubmitETAModal: React.FC<{
  hide: () => void;
  rideId: number;
  rideStartTime: Date;
  show: boolean;
  streetAddress: string;
  timezone: string;
}> = ({ rideId, rideStartTime, hide, show, streetAddress, timezone }) => {
  const dispatch = useDispatch();
  const [dateField, setDateField] = useState(rideStartTime);
  const [timeField, setTimeField] = useState(rideStartTime);
  const { updateRideById } = useTableContext();

  const [submitRideResponse] = useSubmitRideResponseMutation();

  const formatRideTime = () => {
    const day = new Date(dateField);
    const time = new Date(timeField);

    const year = day.getFullYear();
    const month = day.getMonth();
    const date = day.getDate();

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    const milliseconds = time.getMilliseconds();

    const combinedDate = new Date(
      year,
      month,
      date,
      hours,
      minutes,
      seconds,
      milliseconds,
    );

    return combinedDate;
  };

  const computeMinTime = (dateField: Date, rideStartTime: Date) => {
    const selectedDateStr = dateField.toDateString();
    const rideDateStr = new Date(rideStartTime).toDateString();

    if (selectedDateStr === rideDateStr) {
      return new Date(rideStartTime);
    } else {
      const newMinTime = new Date(dateField);
      newMinTime.setHours(0, 0, 0, 0);
      return newMinTime;
    }
  };


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const combinedDate = formatRideTime();

    // Validate if the selected date is the ride start date
    // and the selected time is before the ride start time.
    const rideDateStr = rideStartTime.toDateString();
    const selectedDateStr = new Date(dateField).toDateString();
    if (rideDateStr === selectedDateStr && combinedDate < rideStartTime) {
      dispatch(
        pushFlashMessage({
          text: 'The selected time cannot be before the ride start time.',
          type: 'error',
        })
      );
      return;
    }

    const body = {
      rideId,
      eta: combinedDate,
    };

    submitRideResponse(body)
      .unwrap()
      .then(() => {
        hide();
        dispatch(
          pushToastNotification({
            text: 'Your ETA was successfully submitted.',
            rideId,
            role: 'dispatcher',
            action: 'view ride',
          })
        );
        updateRideById(rideId, { hasBid: true });
      })
      .catch(() => {
        dispatch(
          pushFlashMessage({
            text: 'Sorry, an ETA for this ride cannot be submitted at the moment. Please try again in a few minutes.',
            type: 'error',
          })
        );
      });
  };


  return (
    <Modal
      open={show}
      hide={hide}
      title="Submit ETA"
      subtitle={`When can you arrive at ${streetAddress}?`}
    >
      <form
        className="form p-0"
        id="cancel-ride"
        acceptCharset="UTF-8"
        method="delete"
        onSubmit={handleSubmit}
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <div className="d-flex flex-column flex-grow-1">
              <DateField
                initialDate={rideStartTime}
                minDate={rideStartTime}
                onChange={setDateField}
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <TimeField
                timeInterval="5"
                initialTime={rideStartTime}
                minTime={computeMinTime(dateField, rideStartTime)}
                onChange={setTimeField}
                position="top-end"
                tripTimezone={timezone}
              />
            </div>
          </div>
          <div className="alert-dialog-actions mt-6 flex-row">
            <button
              type="button"
              className="button -medium -inverted-blue"
              onClick={hide}
            >
              Nevermind
            </button>

            <button
              data-test="cancel-ride-submit-btn"
              type="submit"
              className="button -medium"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default SubmitETAModal;
