import React from 'react';

import RideTimes from './RideTimes';

const CancelSeries: React.FC<{
  lastRideDate: string;
  ridesCount: number;
  timeZone: string;
}> = ({ lastRideDate, ridesCount, timeZone }) => {
  return (
    <div>
      <span className="series-message">
        This will cancel the following repeat series until
      </span>{' '}
      <span className="last-ride-date">
        <RideTimes date={lastRideDate} timeZone={timeZone} />
      </span>
      <br />
      <span className="last-ride-date">Total rides: {ridesCount}</span>
    </div>
  );
};

export default CancelSeries;
