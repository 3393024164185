import React, { useState } from 'react';

import DateField from '@root/components/DateField';
import TimeField from '@root/components/TimeField';
import { useDispatch } from 'react-redux';

import { useUpdateRideMutation } from '@/api';
import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { pushFlashMessage } from '@/store/flashMessagesSlice';
import { pushToastNotification } from '@/store/toastNotificationsSlice';
import { CC_RIDE_STATUS_BADGES } from '@/types';
import { formatRideTime } from '@/utils/dateTime';

import Modal from '../Modal';

const ConfirmPickupDropoffModal: React.FC<{
  hide: () => void;
  isPickup: boolean;
  rideId: number;
  rideStartTime: Date;
  show: boolean;
  timezone: string;
}> = ({ rideId, hide, show, timezone, rideStartTime, isPickup }) => {
  const dispatch = useDispatch();
  const { atPickup, completed } = CC_RIDE_STATUS_BADGES;

  const [dateField, setDateField] = useState(new Date());
  const [timeField, setTimeField] = useState(new Date());

  const { updateRideById } = useTableContext();

  const [updateRide] = useUpdateRideMutation();

  const minTime = new Date();
  minTime.setHours(0, 0, 0, 0);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const combinedDate = formatRideTime(dateField, timeField);

    const body = {
      id: rideId,
      currentStatus: isPickup ? 'at_pickup' : 'at_dropoff',
      eventTimestamp: new Date(),
      userConfirmedAt: combinedDate,
    };

    updateRide(body)
      .unwrap()
      .then(() => {
        hide();
        dispatch(
          pushToastNotification({
            text: `Your ${isPickup ? 'pickup' : 'drop-off'} time was successfully submitted.`,
            rideId,
            role: 'care_coordinator',
            action: 'view ride',
          }),
        );
        updateRideById(
          rideId,
          isPickup
            ? { pickedUpAt: combinedDate.toString(), statusBadge: atPickup }
            : {
                completedAt: new Date().toString(),
                droppedOffAt: combinedDate.toString(),
                statusBadge: completed,
              },
        );
      })
      .catch(() => {
        dispatch(
          pushFlashMessage({
            text: `Sorry, a ${isPickup ? 'pickup' : 'drop-off'} time for this ride cannot be submitted at the moment. Please try again in a few minutes.`,
            type: 'error',
          }),
        );
      });
  };

  return (
    <Modal
      open={show}
      hide={hide}
      title={isPickup ? 'Confirm pickup' : 'Confirm drop-off'}
      subtitle=""
    >
      <div style={{ cursor: 'pointer' }} className="pb-2">
        {isPickup
          ? 'When was the rider picked up?'
          : 'When was the rider dropped off?'}
      </div>
      <form
        className="form p-0"
        id="cancel-ride"
        acceptCharset="UTF-8"
        method="delete"
        onSubmit={handleSubmit}
      >
        <div className="d-flex flex-column">
          <div className="d-flex">
            <DateField
              initialDate={new Date()}
              minDate={rideStartTime}
              onChange={setDateField}
            />
            <TimeField
              timeInterval="5"
              initialTime={new Date()}
              minTime={rideStartTime}
              onChange={setTimeField}
              position="top-end"
              tripTimezone={timezone}
            />
          </div>
          <div className="alert-dialog-actions mt-6">
            <button
              type="button"
              className="button -medium -inverted-blue"
              onClick={hide}
            >
              Nevermind
            </button>

            <button
              data-test="cancel-ride-submit-btn"
              type="submit"
              className="button -medium"
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ConfirmPickupDropoffModal;
