import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import dayjs from '@/lib/dayjs'
import { coerceTimeToDate } from '@/src/util/FormatDate';

import 'react-datepicker/dist/react-datepicker.css';

const CustomInput = forwardRef((props, ref) => {
  const timeZone = dayjs().tz(props.timezone).format('z');
  const newTime = `${props.value} ${timeZone}`;
  const newProps = { ...props, value: newTime };
  return <input {...newProps} ref={ref} />;
});

const TimeField = ({
  initialTime,
  timeInterval,
  minTime,
  maxTime,
  onChange,
  position,
  className = '-large',
  tripTimezone,
}) => {
  const [time, setTime] = useState(initialTime);
  const timeFieldClassNames = classNames(['input -clock mr-sm-1 -full-mobile picker__input ml-sm-1', className])

  const timeChanged = (updatedTime) => {
    setTime(updatedTime);
    onChange(updatedTime);
  };

  return (
    <DatePicker
      selected={time}
      minTime={minTime}
      maxTime={maxTime}
      onChange={timeChanged}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeInterval}
      popperPlacement={position}
      dateFormat="h:mm aa"
      className={timeFieldClassNames}
      customInput={<CustomInput timezone={tripTimezone} />}
    />
  );
};

TimeField.propTypes = {
  initialTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  minTime: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  position: PropTypes.string,
  timeInterval: PropTypes.string.isRequired,
  tripTimezone: PropTypes.string,
};

TimeField.defaultProps = {
  initialTime: new Date(),
  maxTime: dayjs().endOf('day').toDate(),
  minTime: new Date(),
  position: '',
};

export default TimeField;
