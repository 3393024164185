/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import dayjs from '@/lib/dayjs'

new Component('ride-detail-bid-countdown', [], (c) => {
  const $this = $(c.this);
  const $biddingHelper = new BiddingHelper();
  const $display = $this.find('.status-message');
  const $displayIcon = $this.find('.status-icon');
  const $statusData = $this.find('#status-data');
  const $preferredProviderTooltip = $this.find('.preferred-provider-tooltip');
  const $loader = $this.find('.loader-spin');
  const $ppTimer = $this.find('.countdown');
  const $ppTimerText = $this.find('.countdown-text');
  const INTERVAL = 1000;
  const DISPATCHER_STATUS = {
    preferred: 'Preferred',
    processing: 'Processing',
    responded: 'Responded',
  };

  // Sets the status message after cutoff time
  //
  // @param {Time} cutoffTime
  // @return {void}
  const setDisplayAfterCutoff = function (cutoffTime) {
    const interval = cutoffTime.diff(dayjs(), 'second') * INTERVAL;
    return setTimeout(setDisplay, interval, DISPATCHER_STATUS.processing);
  };

  // Displays the timer on the ride detail page
  //
  // @param {Time} cutoffTime
  // @return {void}
  const timer = function (cutoffTime) {
    let timeDisplayInterval = null;

    const updateTimerStatus = function (timeToCutoff) {
      const display = `${Math.floor(timeToCutoff / 60)} MIN ${timeToCutoff % 60} S`;
      $display.html(display);
      return $statusData?.data('interval_id', timeDisplayInterval);
    };

    const run = function () {
      if (dayjs().add(1, 'second').isAfter(cutoffTime)) {
        setDisplay(DISPATCHER_STATUS.processing);
        return clearInterval(timeDisplayInterval);
      }
      const timeToCutoff = cutoffTime.diff(dayjs(), 'second');
      return updateTimerStatus(timeToCutoff);
    };
    run();
    return (timeDisplayInterval = setInterval(run, INTERVAL));
  };

  // TODO: Rename to generic name when general community timer is also moved
  // under status badge
  //
  // Display timer under the status badge on the dispatcher community page
  //
  // @param {Time} cutoffTime
  // @return {Void}
  const setPpTimer = function (cutoffTime) {
    let timeDisplayInterval = null;

    const run = function () {
      const timeLeft = $biddingHelper.remainingTimeCalculator(cutoffTime);

      if (timeLeft) {
        $ppTimerText.html(timeLeft);
        return $statusData?.data('interval_id', timeDisplayInterval);
      }
      setDisplay(DISPATCHER_STATUS.processing);
      return clearInterval(timeDisplayInterval);
    };

    run();
    return (timeDisplayInterval = setInterval(run, INTERVAL));
  };

  // Sets the status message and do some UI changes on the dispatcher ride
  // detail page
  //
  // @param {String} str
  // @return {void}
  var setDisplay = function (message) {
    if (message === DISPATCHER_STATUS.preferred) {
      $this.addClass('-aqua tooltip');
      if ($preferredProviderTooltip.is(':hidden')) {
        $preferredProviderTooltip.show();
      }
      if ($ppTimer.is(':hidden')) {
        $ppTimer.show();
      }
    } else {
      $this.removeClass('-aqua tooltip');
      if ($preferredProviderTooltip.is(':visible')) {
        $preferredProviderTooltip.hide();
      }
      if ($ppTimer.is(':visible')) {
        $ppTimer.hide();
      }
    }

    if (message === DISPATCHER_STATUS.processing) {
      $this.addClass('-processing');
      if ($loader.is(':hidden')) {
        $loader.show();
      }
      $('.submit-btn').hide();
    } else {
      $this.removeClass('-processing');
      if ($loader.is(':visible')) {
        $loader.hide();
      }
    }

    if (message === DISPATCHER_STATUS.responded) {
      if ($displayIcon.is(':hidden')) {
        $displayIcon.show();
        $this.removeClass('-deep-red');
      }
    } else if ($displayIcon.is(':visible')) {
      $displayIcon.hide();
    }

    return $display.html(message);
  };

  return $(document).ready(() => {
    const data = $this.data();
    const { timerStartTime } = data;

    if (timerStartTime && !data.declined && !data.willCall) {
      const cutoffTime = dayjs
        .utc(timerStartTime)
        .add(data.biddingWindow, 'second');
      if (data.processingRide) {
        return setDisplay(DISPATCHER_STATUS.processing);
      }
      if (data.hasBid) {
        setDisplay(DISPATCHER_STATUS.responded);
        return setDisplayAfterCutoff(cutoffTime);
      }
      if (data.ppCommunity) {
        setDisplay(DISPATCHER_STATUS.preferred);
        return setPpTimer(cutoffTime);
      }
      if (data.timerStartTime) {
        return timer(cutoffTime);
      }
    }
  });
});
