import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useDeleteRideMutation } from '@/api';
import { pushFlashMessage } from '@/store/flashMessagesSlice';

import './main.scss';

import type { DeleteRide } from '@/types';

import { toSnake } from '@root/util/CaseConvert';

import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { error } from '@/lib/@datadog/browser-logs';
import * as DropdownMenu from '@/lib/@radix-ui/react-dropdown-menu';
import { pushToastNotification } from '@/store/toastNotificationsSlice';
import { CANCELLATION_REASONS_TEXT } from '@/types';

import Modal from '../QuickActionCell/Modal';

const BulkCancelModal = ({
  rideIds,
  hide,
  show,
}: {
  hide: () => void;
  rideIds: number[];
  show: boolean;
}) => {
  const [additionalInfo, setAdditionalInfo] = React.useState('');
  const [selectedReason, setSelectedReason] = React.useState<
    keyof typeof CANCELLATION_REASONS_TEXT | ''
  >('');
  const { removeRideById, deselectAllRides } = useTableContext();

  const dispatch = useDispatch();

  const [deleteRide] = useDeleteRideMutation();

  const handleReasonChange = (value: string) =>
    setSelectedReason(value as keyof typeof CANCELLATION_REASONS_TEXT);
  const handleAdditionalInfoChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setAdditionalInfo(event.target.value);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const submitPromises = rideIds.map((rideId) => {
      const params: DeleteRide = {
        body: {
          additionalCancelationInfo: additionalInfo,
          cancelationReason: toSnake(selectedReason) as string,
          sendNotifications: true,
          id: rideId,
        },
        rideId,
      };

      return deleteRide(params)
        .unwrap()
        .then(() => {
          removeRideById(rideId);
        })
        .catch((err) => {
          error(JSON.stringify(err));
          dispatch(
            pushFlashMessage({
              text: `Sorry, ride ${rideId} cannot be canceled at the moment. Please try again in a few minutes.`,
              type: 'error',
            }),
          );
        });
    });

    try {
      await Promise.all(submitPromises);

      hide();
      dispatch(
        pushToastNotification({
          text: `${rideIds.length} rides successfully cancelled.`,
          action: 'success',
        }),
      );
      deselectAllRides();
      setAdditionalInfo('');
      setSelectedReason('');
    } catch (err) {
      error(JSON.stringify(err));
      dispatch(
        pushFlashMessage({
          text: 'An error occurred while cancelling rides. Please try again.',
          type: 'error',
        }),
      );
    }
  };

  return (
    <Modal
      open={show}
      hide={hide}
      title="Cancel selected rides"
      subtitle="The reason you select will be applied to all rides being cancelled."
    >
      <form
        className="form p-0"
        id="cancel-ride"
        acceptCharset="UTF-8"
        method="delete"
        onSubmit={handleSubmit}
      >
        <div className="field">
          <label htmlFor="cancelation_reason" className="label">
            Reason <span className="required">*</span>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger asChild>
                <DropdownMenu.Label className="DropdownMenuLabel">
                  {(selectedReason &&
                    CANCELLATION_REASONS_TEXT[selectedReason]) ||
                    'Select a reason'}
                </DropdownMenu.Label>
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <DropdownMenu.Content
                  className="DropdownMenuContent"
                  sideOffset={5}
                >
                  <DropdownMenu.RadioGroup
                    value={selectedReason}
                    onValueChange={handleReasonChange}
                    id="cancelation_reason"
                  >
                    {Object.entries(CANCELLATION_REASONS_TEXT).map(
                      ([key, text]) => (
                        <DropdownMenu.RadioItem
                          className="DropdownMenuRadioItem"
                          value={key}
                        >
                          {text}
                        </DropdownMenu.RadioItem>
                      ),
                    )}
                  </DropdownMenu.RadioGroup>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </label>
        </div>

        <div className="field mt-1" role="button" tabIndex={0}>
          <label htmlFor="additional_cancelation_info" className="label">
            Additional information
            <textarea
              id="additional_cancelation_info"
              name="additional_cancelation_info"
              value={additionalInfo}
              onChange={handleAdditionalInfoChange}
              className="input -tall"
            />
          </label>
        </div>

        <div className="alert-dialog-actions">
          <button
            type="button"
            className="button py-2 -inverted-blue"
            onClick={hide}
          >
            Nevermind
          </button>

          <button
            data-test="cancel-ride-submit-btn"
            type="submit"
            className="button py-2 -cancel"
          >
            Cancel Rides
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default BulkCancelModal;
