import dayjs from '@/lib/dayjs'

const reactCommunityRidesTable = () =>
  document.getElementById('dispatcher-community-rides-table-react');

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(document).on('turbolinks:load', () => {
  if (reactCommunityRidesTable()) {
    return;
  }
  const disable_socket_element = document.querySelector('#disable-web-sockets');

  if (
    disable_socket_element &&
    disable_socket_element.dataset &&
    disable_socket_element.dataset.disable === 'true'
  ) {
    return;
  }

  const $userRoleElement = document.querySelector('#current-user-role');
  const scope =
    $userRoleElement && $userRoleElement.dataset
      ? $userRoleElement.dataset.role
      : undefined;

  const $rideInfo = document.querySelector('#ride-info');
  const rideId =
    $rideInfo && $rideInfo.dataset ? $rideInfo.dataset.rideId : undefined;

  const $otherRideInfo = document.querySelector('#other-ride-info');
  const otherRideId =
    $otherRideInfo && $otherRideInfo.dataset
      ? $otherRideInfo.dataset.otherRideId
      : undefined;

  if (
    Number.isInteger(parseInt(rideId)) &&
    ['dispatcher', 'care_coordinator'].indexOf(scope) !== -1
  ) {
    return (App.ride_detail_notifications = App.cable.subscriptions.create(
      {
        channel: 'RideDetailChannel',
        ride: rideId,
        scope,
      },
      {
        connected() { },
        // Called when the subscription is ready for use on the server

        disconnected() { },
        // Called when the subscription has been terminated by the server

        received(data) {
          if (reactCommunityRidesTable()) {
            return;
          }
          // Handles all the updates for dispatcher ride detail page
          //
          // @param {Object} data
          // @return {void}
          const updateDispatcherInfo = function (data) {
            const $status = $('.status');
            const $statusMessage = $('.status-message');
            const $statusData = $('#status-data');
            const $statusIcon = $('.status-icon');
            const $processingLoader = $('.loader-spin');
            const $bidSubmitBtn = $('.submit-btn');
            const $preferredProviderTooltip = $('.preferred-provider-tooltip');
            const $ppTimer = $('.countdown');
            const $ppTimerText = $('.countdown-text');

            const $biddingHelper = new BiddingHelper();
            const currentDispatcherId = $('body').data().currentUserId;
            const assignedDispatcherIds = data.dispatcher_ids || [];

            // Stops the previous timer if in progress
            //
            // @return {void}
            const clearRunningTimer = function () {
              const intervalData = $statusData?.data();
              const intervalId = intervalData?.interval_id;
              if (intervalId != null) {
                return clearInterval(intervalId);
              }
            };

            // Sets the status message and do some UI changes
            //
            // @param {String} str
            // @return {void}
            const setDisplay = function (str) {
              if (str === 'Processing') {
                $status.addClass('-processing');
                $statusMessage.html('Processing');
                if ($processingLoader.is(':hidden')) {
                  $processingLoader.show();
                }
                $bidSubmitBtn.hide();
              } else {
                $status.removeClass('-processing');
                if ($processingLoader.is(':visible')) {
                  $processingLoader.hide();
                }
              }

              if (str === 'Preferred') {
                $statusMessage.html(str);
                $status.addClass('-aqua tooltip');
                if ($preferredProviderTooltip?.is(':hidden')) {
                  $preferredProviderTooltip.show();
                }
                if ($ppTimer?.is(':hidden')) {
                  return $ppTimer.show();
                }
              } else {
                $status.removeClass('-aqua tooltip');
                if ($preferredProviderTooltip?.is(':visible')) {
                  $preferredProviderTooltip.hide();
                }
                if ($ppTimer?.is(':visible')) {
                  return $ppTimer.hide();
                }
              }
            };

            // Resets the timer while ride is within the window and convert
            // the status to `Processing` after timer ends
            //
            // @return {void}
            const resetTimer = function () {
              const INTERVAL = 1000;
              const cutoffTime = dayjs
                .utc(data.bidding_countdown_starts_at)
                .add(data.ride_bidding_window, 'second');
              let timeDisplayInterval = null;

              const updateTimerStatus = function (timeToCutoff) {
                const display = `${Math.floor(timeToCutoff / 60)} MIN ${timeToCutoff % 60} S`;
                $statusMessage.html(display);
                return $statusData.data('interval_id', timeDisplayInterval);
              };

              const run = function () {
                if (dayjs().add(1, 'second').isAfter(cutoffTime)) {
                  setDisplay('Processing');
                  return clearInterval(timeDisplayInterval);
                }
                const timeToCutoff = cutoffTime.diff(dayjs(), 'second');
                return updateTimerStatus(timeToCutoff);
              };

              run();
              return (timeDisplayInterval = setInterval(run, INTERVAL));
            };

            // TODO: Rename to generic name when general community timer is also moved
            // under status badge
            //
            // Display timer under the status badge on the dispatcher community page
            //
            // @param {Time} cutoffTime
            // @return {void}
            const setPpTimer = function () {
              let timeDisplayInterval = null;
              const cutoffTime = dayjs
                .utc(data.bidding_countdown_starts_at)
                .add(data.ride_bidding_window, 'second');
              const TIME_INTERVAL = 1000;

              const run = function () {
                const timeLeft =
                  $biddingHelper.remainingTimeCalculator(cutoffTime);
                if (timeLeft) {
                  $ppTimerText.html(timeLeft);
                  return $statusData.data('interval_id', timeDisplayInterval);
                }
                setDisplay('Processing');
                return clearInterval(timeDisplayInterval);
              };

              run();
              return (timeDisplayInterval = setInterval(run, TIME_INTERVAL));
            };

            // Updating the status badge class and status message
            $status.attr(
              'class',
              `status -large relative ${data.current_status_class}`,
            );
            if ($processingLoader.is(':visible')) {
              $processingLoader.hide();
            }

            if ($statusIcon.is(':visible')) {
              $statusIcon.hide();
            }
            if (data.humanized_current_status != null) {
              $statusMessage.html(data.humanized_current_status);
            }

            // Clear if timer running to convert the current status to `Processing`
            clearRunningTimer();

            if (data.humanized_current_status === 'Available') {
              $bidSubmitBtn.show();
              if ($status.data()?.declined) {
                return $statusMessage.html('Declined');
              }
              if (data.within_bidding_window) {
                // TODO: Currently implementing separate logic for
                // preferred provider, will merge once timer is separated for
                // general community
                if (data.pp_community) {
                  setDisplay('Preferred');
                  return setPpTimer();
                }
                return resetTimer();
              }
            } else if (data.humanized_current_status === 'Assigned') {
              // If ride is assigned to the dispatcher's TC
              if (assignedDispatcherIds.includes(currentDispatcherId)) {
                $('.decline-link').html('Release ride');
                $('.decline-modal-btn').val('Release ride');
                $('.claim-modal-btn').hide();
                $bidSubmitBtn.hide();
                return $ppTimer.hide();
              }
              // This will redirect dispatcher to the root path if ride is not assigned
              return window.location.reload();
            }
          };

          const updateAuthorizationInfo = function (auth_info) {
            // `rd` stands for ride detail, which we are using to prevent ID clashes.
            $('#rd-transport-provider-phone').html(
              auth_info.transport_provider_phone,
            );
            $('#rd-auth-status').html(auth_info.auth_status);
            return $('#rd-denial-reason').html(auth_info.denial_reason);
          };

          const { action } = $('body').data();

          if (scope === 'dispatcher' && action === 'show_ride') {
            return updateDispatcherInfo(data);
          }
          if (scope === 'care_coordinator') {
            if (data.auth_info != null) {
              return updateAuthorizationInfo(data.auth_info);
            }
          }
        },
      },
    ));
  }
  if (App.ride_detail_notifications != null) {
    const activeSubscriptions = App.cable.subscriptions.findAll(
      App.ride_detail_notifications.identifier,
    );
    activeSubscriptions.map((sub) => sub.unsubscribe());
    return (App.ride_detail_notifications = null);
  }
});
