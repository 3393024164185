import { useEffect } from 'react';

import type { Ride, Role } from '@/types';

import useWebsocket, { ReadyState } from 'react-use-websocket';

const WS = 'ws';
const WSS = 'wss';
const PATH = '/cable';

const scheme = process.env.NODE_ENV === 'production' ? WSS : WS;

export const SOCKET_URL = `${scheme}://${window.location.host}${PATH}`;

const useRtWebsocket = <T = { message: Ride }>(
  channel: string,
  scope: Role,
  userId: number,
) => {
  const identifier = `{"channel": "${channel}", "scope": "${scope}", "current_user_id": "${userId}"}`;

  const ws = useWebsocket<T>(SOCKET_URL, {
    // onOpen: () => console.log('opened'),
    // shouldReconnect: (closeEvent) => false, // TODO: Implement reconnect logic?
    share: true, // Prevent multiple connects to the same URL.
    filter: ({ data }) => {
      const message = JSON.parse(data as string) as {
        identifier: string;
        type: string;
      };

      if (
        ['ping', 'welcome', 'confirm_subscription'].includes(message.type) ||
        message.identifier !== identifier
      ) {
        // updateHeartbeat();
        return false;
      }

      return true;
    },
  });

  const { sendJsonMessage, readyState } = ws;

  useEffect(() => {
    /**
     * After a websocket connection is open, subscribe to the appropriate channel.
     */
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        command: 'subscribe',
        identifier, // This will become a nested stringified object in the message but that is how ActionCable expects it.
      });
    }
  }, [identifier, readyState, sendJsonMessage]);

  return ws;
};

export default useRtWebsocket;
