/* eslint-disable no-return-assign */
import * as React from 'react';

import type { RideColumn } from '@/features/RidesTable/types';
import type { AppDispatch } from '@/store';
import type {
  AuthStatus,
  CcRideStatusBadges,
  Entries,
  GlobalSSRData,
  Ride,
  VehicleType,
} from '@/types';

import { Cross2Icon } from '@radix-ui/react-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';

import BulkActionCell from '@/components/RidesTable/BulkActionCell';
import BulkActionHeaderBar from '@/components/RidesTable/BulkActionCell/BulkActionHeaderBar';
import CancelationReasonCell from '@/components/RidesTable/CancelationReasonCell';
import CanceledAtCell from '@/components/RidesTable/CanceledAtCell';
import RideStatusBadgeCell from '@/components/RidesTable/careCoordinator/RideStatusBadgeCell';
import CompletedAtCell from '@/components/RidesTable/CompletedAtCell';
import DropoffLocationCell from '@/components/RidesTable/DropoffLocationCell';
import PatientCell from '@/components/RidesTable/PatientCell';
import PickupLocationCell from '@/components/RidesTable/PickupLocationCell';
import PickupTimeCell from '@/components/RidesTable/PickupTimeCell';
import QuickActionCellCareCoordinator from '@/components/RidesTable/QuickActionCell/careCoordinator';
import RideBookerCell from '@/components/RidesTable/RideBookerCell';
import VehicleTypeCell from '@/components/RidesTable/VehicleTypeCell';
import { useAuth } from '@/contexts/AuthProvider';
import Filters from '@/features/RidesTable/components/Filters';
import Header from '@/features/RidesTable/components/Header';
import ActionHeader from '@/features/RidesTable/components/Table/ActionHeader';
import TableHandler from '@/features/RidesTable/components/TableHandler';
import useUserPreferredColumns from '@/features/RidesTable/hooks/useUserPreferredColumns';
import {
  resetFilters,
  selectRideFilters,
  setAuthStatuses,
  setEndDate,
  setItemsPerPage,
  setPage,
  setSearch,
  setShowWillCall,
  setSortField,
  setSortType,
  setStartDate,
  setStatusBadge,
  setVehicleTypes,
} from '@/features/RidesTable/store/cc/ridesFilterSlice';
import {
  selectSelectedRides,
  setAllRidesSelected,
  setSelectedRides,
} from '@/features/RidesTable/store/selectedRidesSlice';
import { CC_RIDE_STATUS_BADGE_TEXT } from '@/features/RideStatusBadge/types';
import {
  CC_RIDE_STATUS_BADGES,
  CC_VEHICLE_TYPES,
  DEFAULT_CC_FILTER_STATUSES,
} from '@/types';

const {
  canceled: _canceled, // eslint-disable-line @typescript-eslint/no-unused-vars
  completed: _completed, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...statusDropdownItems
} = CC_RIDE_STATUS_BADGE_TEXT;

const BASE_COLUMNS: RideColumn[] = [
  {
    Cell: PatientCell,
    removeable: false,
    label: 'Rider Name',
    sortKey: 'rider_name',
    href: ({ id }) => `/rides/${id}`,
  },
  {
    Cell: RideBookerCell,
    removeable: false,
    label: 'Booker Name',
    sortKey: 'booker_name',
    hideKey: 'booker_name',
    href: ({ id }) => `/rides/${id}`,
  },
  {
    Cell: PickupLocationCell,
    label: 'Pickup',
    removeable: false,
    sortKey: 'pickup_location',
    href: ({ id }) => `/rides/${id}`,
  },
  {
    Cell: VehicleTypeCell,
    label: 'Vehicle Type',
    removeable: false,
    sortKey: 'vehicle_type',
    hideKey: 'vehicle',
    href: ({ id }) => `/rides/${id}`,
  },
  {
    Cell: DropoffLocationCell,
    label: 'Drop-off',
    removeable: false,
    sortKey: 'dropoff_location',
    href: ({ id }) => `/rides/${id}`,
  },
];

const CANCELED_COLUMNS = [
  ...BASE_COLUMNS,
  {
    Cell: CanceledAtCell,
    label: 'Canceled At',
    removeable: false,
    sortKey: 'canceled_at',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
  {
    Cell: CancelationReasonCell,
    label: 'Cancel Reason',
    removeable: false,
    sortKey: 'cancel_reason',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
  {
    Cell: QuickActionCellCareCoordinator,
    Header: ActionHeader,
    label: '',
    sortKey: 'quick_action',
    removeable: false,
    href: ({ id }: Ride) => `/rides/${id}`,
  },
];

const COMPLETED_COLUMNS = [
  ...BASE_COLUMNS,
  {
    Cell: CompletedAtCell,
    label: 'Completed At',
    removeable: false,
    sortKey: 'completed_at',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
  {
    Cell: RideStatusBadgeCell,
    label: 'Status',
    removeable: false,
    sortKey: 'current_status',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
  {
    Cell: QuickActionCellCareCoordinator,
    Header: ActionHeader,
    label: '',
    sortKey: 'quick_action',
    removeable: false,
    href: ({ id }: Ride) => `/rides/${id}`,
  },
];

const SCHEDULED_COLUMNS = [
  ...BASE_COLUMNS,
  {
    Cell: PickupTimeCell,
    label: 'Pickup Time',
    removeable: false,
    sortKey: 'start_time',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
  {
    Cell: RideStatusBadgeCell,
    label: 'Status',
    removeable: false,
    sortKey: 'current_status',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
  {
    Cell: QuickActionCellCareCoordinator,
    Header: ActionHeader,
    label: '',
    removeable: false,
    sortKey: 'quick_action',
    href: ({ id }: Ride) => `/rides/${id}`,
  },
];

const vehicleList = Object.entries(CC_VEHICLE_TYPES) as Entries<
  typeof CC_VEHICLE_TYPES
>;

const Scheduled = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useAuth();
  const { reactDashboardBulkActionsCc } = useFlags();

  const selectedRides = useSelector(selectSelectedRides);
  const filters = useSelector(selectRideFilters);

  const clearSelectedRides = React.useCallback(() => {
    dispatch(setSelectedRides([]));
    dispatch(setAllRidesSelected(false));
  }, [dispatch]);

  const setPaginationPage = (page: number) => {
    dispatch(setPage({ page }));
    clearSelectedRides();
  };

  const onStatusBtnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    statuses: CcRideStatusBadges[],
  ) => {
    e.preventDefault();
    dispatch(setStatusBadge(statuses));
  };

  const completedIsActive =
    filters.statusBadge.length === 1 &&
    filters.statusBadge[0] === CC_RIDE_STATUS_BADGES.completed;

  const canceledIsActive =
    filters.statusBadge.length === 1 &&
    filters.statusBadge[0] === CC_RIDE_STATUS_BADGES.canceled;

  let columns = [];

  if (canceledIsActive) {
    columns = CANCELED_COLUMNS;
  } else if (completedIsActive) {
    columns = COMPLETED_COLUMNS;
  } else {
    columns = SCHEDULED_COLUMNS;
  }

  if (reactDashboardBulkActionsCc) {
    const bulkActionColumnExists = SCHEDULED_COLUMNS.some(
      (column) => column.label === 'Bulk Action',
    );

    if (!bulkActionColumnExists) {
      SCHEDULED_COLUMNS.unshift({
        Cell: BulkActionCell,
        Header: ActionHeader,
        label: 'Bulk Action',
      } as unknown as RideColumn);
    }
  }

  React.useEffect(() => {
    if (completedIsActive || canceledIsActive) {
      clearSelectedRides();
    }
  }, [completedIsActive, canceledIsActive, clearSelectedRides]);

  const userPreferredColumns = useUserPreferredColumns(
    columns,
    'careCoordinator',
    'scheduled',
  );

  return (
    <>
      <Header.Root>
        <div className="d-block d-sm-flex" style={{ gap: '0.25rem' }}>
          <Header.RangePicker
            endTime={filters.endTime}
            startTime={filters.startTime}
            setStartTime={(date: string) => dispatch(setStartDate(date))}
            setEndTime={(date: string) => dispatch(setEndDate(date))}
          />
          <Header.Search
            search={filters.search}
            setSearch={(input: string) => dispatch(setSearch(input))}
          />
        </div>

        <Header.StatusContainer>
          <Header.StatusButton
            active={!(completedIsActive || canceledIsActive)}
            onClick={(e) => onStatusBtnClick(e, DEFAULT_CC_FILTER_STATUSES)}
            status={{
              statusBadge: DEFAULT_CC_FILTER_STATUSES,
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Scheduled
          </Header.StatusButton>
          <Header.StatusButton
            active={completedIsActive}
            onClick={(e) =>
              onStatusBtnClick(e, [CC_RIDE_STATUS_BADGES.completed])
            }
            status={{
              statusBadge: [CC_RIDE_STATUS_BADGES.completed],
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Completed
          </Header.StatusButton>

          <Header.StatusButton
            active={canceledIsActive}
            onClick={(e) =>
              onStatusBtnClick(e, [CC_RIDE_STATUS_BADGES.canceled])
            }
            status={{
              statusBadge: [CC_RIDE_STATUS_BADGES.canceled],
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Canceled
          </Header.StatusButton>
        </Header.StatusContainer>

        {/* TODO: Should this be in auth provider? */}
        {/* eslint-disable-next-line no-underscore-dangle */}
        {(window as GlobalSSRData)._unsafeSSRData.global.userMetadata
          .canBookTrip && (
          <div className="mobile-book-trip-container">
            <a
              href="/book_trip/new"
              className="button book-trip -medium -block-sm"
              data-turbolinks={false}
              data-test="book-trip-btn"
            >
              Book Trip
            </a>
          </div>
        )}
      </Header.Root>

      <TableHandler
        columns={userPreferredColumns}
        filters={filters}
        setPage={setPaginationPage}
        rowsPerPage={filters.items}
        onChangeRowsPerPage={(selected: number) =>
          dispatch(setItemsPerPage(selected))
        }
        sortType={filters.sortType}
        onChangeSortType={(type: string | null) => dispatch(setSortType(type))}
        sortField={filters.sortField}
        onChangeSortField={(field: string) => dispatch(setSortField(field))}
      >
        <BulkActionHeaderBar.Root visible={selectedRides.length > 0}>
          <div className="d-flex mb-3 mb-sm-0" style={{ gap: '0.25rem' }}>
            <BulkActionHeaderBar.BulkCancelOption
              selectedRides={selectedRides}
            />
          </div>
        </BulkActionHeaderBar.Root>

        <Filters.Root visible={selectedRides.length === 0}>
          <div className="d-flex mb-3 mb-sm-0" style={{ gap: '0.25rem' }}>
            <Filters.VehicleType
              options={vehicleList}
              types={filters.vehicleTypes}
              setTypes={(types: VehicleType[]) =>
                dispatch(setVehicleTypes(types))
              }
            />
            <Filters.RideBookers />
            {!(completedIsActive || canceledIsActive) && (
              <Filters.Status
                defaultStatuses={DEFAULT_CC_FILTER_STATUSES}
                currentlySelected={filters.statusBadge}
                items={statusDropdownItems}
                onClose={(selected) =>
                  dispatch(setStatusBadge(selected as CcRideStatusBadges[]))
                }
              />
            )}
            <Filters.AuthStatus
              statuses={filters.authStatuses}
              setStatuses={(types: AuthStatus[]) =>
                dispatch(setAuthStatuses(types))
              }
            />
            <Filters.WillCall
              willCall={filters.willCall}
              setWillCall={(wc: boolean | null) =>
                dispatch(setShowWillCall(wc))
              }
            />
            <Filters.ClearFilters
              visible={!filters.isInit}
              reset={() => dispatch(resetFilters())}
              className="t-grey-50"
            >
              <Cross2Icon />
            </Filters.ClearFilters>
          </div>
          <Filters.EditColumns
            columns={[
              { text: 'Ride booker', key: 'booker_name' },
              { text: 'Vehicle type', key: 'vehicle' },
            ]}
            userRole="careCoordinator"
            page="scheduled"
          />
        </Filters.Root>
      </TableHandler>
    </>
  );
};

export default Scheduled;
