import React from 'react';

import { formatTimehhmmA } from '@/utils/dateTime';

import RideTimes from './RideTimes';

const CancelSingleRide: React.FC<{
  rideTime: string;
  rideTimezone: string;
}> = ({ rideTime, rideTimezone }) => {
  return (
    <div className="single-ride-details">
      <span className="ride-type">Ride detail</span> <br />
      <span className="ride-time-date">
        <RideTimes date={rideTime} timeZone={rideTimezone} />
        <br />
        {formatTimehhmmA(rideTime, rideTimezone)}
      </span>
    </div>
  );
};

export default CancelSingleRide;
