import React from 'react';

import { useDispatch } from 'react-redux';

import { useCreateRideAttemptMutation } from '@/api';
import { pushFlashMessage } from '@/store/flashMessagesSlice';
import { pushToastNotification } from '@/store/toastNotificationsSlice';

import Modal from '../Modal';

const RedispatchRideModal: React.FC<{
  hide: () => void;
  rideId: number;
  show: boolean;
}> = ({ rideId, hide, show }) => {
  const dispatch = useDispatch();

  const [createRideAttempt] = useCreateRideAttemptMutation();

  const minTime = new Date();
  minTime.setHours(0, 0, 0, 0);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createRideAttempt(rideId)
      .unwrap()
      .then(() => {
        hide();
        dispatch(
          pushToastNotification({
            text: 'Ride successfully redispatched.',
            rideId,
            role: 'care_coordinator',
            action: 'view ride',
          }),
        );
      })
      .catch(() => {
        dispatch(
          pushFlashMessage({
            text: `Sorry, this ride cannot be redispatched at the moment. Please try again in a few minutes.`,
            type: 'error',
          }),
        );
      });
  };

  return (
    <Modal open={show} hide={hide} title="Redispatch ride" subtitle="">
      <div style={{ cursor: 'pointer' }} className="pb-2">
        Are you sure you want to redispatch this rideshare ride?
      </div>
      <form
        className="form p-0"
        id="cancel-ride"
        acceptCharset="UTF-8"
        method="delete"
        onSubmit={handleSubmit}
      >
        <div className="d-flex flex-column">
          <div className="alert-dialog-actions mt-6">
            <button
              type="button"
              className="button -medium -inverted-blue"
              onClick={hide}
            >
              Nevermind
            </button>

            <button
              data-test="cancel-ride-submit-btn"
              type="submit"
              className="button -medium"
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default RedispatchRideModal;
