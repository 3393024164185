import { useState } from 'react';
import * as React from 'react';

import { ChevronDownIcon } from '@radix-ui/react-icons';

import Separator from '@/components/Separator';
import * as Popover from '@/lib/@radix-ui/react-popover';

import CheckboxItem from './CheckboxItem';

const filterLabel = 'Status';

const Status: React.FC<{
  currentlySelected: string[];
  defaultStatuses: string[];
  items: Record<string, string>;
  onClose: (selected: string[]) => void;
}> = ({ defaultStatuses, currentlySelected, items, onClose }) => {
  const [selectedStatuses, setSelectedStatuses] = useState(currentlySelected);

  // Determine if all items are currently selected
  const isAllSelected = selectedStatuses.length === defaultStatuses.length;

  const handleClose = () =>
    currentlySelected !== selectedStatuses ? onClose(selectedStatuses) : null;

  const handleCheck = (status: string) => {
    const present = selectedStatuses.includes(status);

    if (present) {
      setSelectedStatuses(
        selectedStatuses.filter((statuses: string) => status !== statuses),
      );
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      // If everything is selected, deselect all
      setSelectedStatuses([]);
    } else {
      // If anything is unselected, select all
      setSelectedStatuses(Object.keys(items));
    }
  };

  const getFilterLabel = () => {
    if (currentlySelected.length === defaultStatuses.length) {
      return filterLabel;
    }

    if (currentlySelected.length === 1) {
      return `${filterLabel}: ${items[currentlySelected[0]]}`;
    }

    return `${filterLabel}: Multiple`;
  };

  return (
    <div className="">
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {getFilterLabel()}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content px-0 py-3"
            align="start"
            onCloseAutoFocus={handleClose}
          >
            <div className="px-4">
              <CheckboxItem
                text={isAllSelected ? 'Deselect all' : 'Select all'}
                key="select-all"
                checked={isAllSelected}
                onCheckedChange={handleSelectAll}
              />
            </div>

            <Separator className="my-3" />

            <div className="px-4">
              {Object.keys(items).map((key) => (
                <CheckboxItem
                  text={items[key]}
                  key={key}
                  onCheckedChange={() => handleCheck(key)}
                  checked={selectedStatuses.includes(key)}
                />
              ))}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default Status;
